import { PayloadAction } from "@reduxjs/toolkit";
import { put, all, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { call } from "redux-saga/effects";
import { apiCall } from "../utils/apiCall";
import { Produto } from "./types/produto";
import { novoProduto } from "./data/produto";
import { entradaesaidaActions } from "./recucer";
import { formatError } from "../utils/formatError";
import { ListPayload } from "../sistema/types/listPayload";
import { ApiPaginationRes } from "../sistema/types/apiPaginationRes";
import { Sacola } from "./types/sacola";
import { novaSacola } from "./data/sacola";
import { NotaPromissoria } from "./types/nota_promissoria";
import { novaNotaPromissoria } from "./data/nota_promissoria";

function* produtosRequestWorker({ payload }: PayloadAction<ListPayload>) {
  try {
    const res: AxiosResponse<ApiPaginationRes<Produto[]>> = yield call(apiCall, {
      url: `produtos?termo=${payload.termo}&page=${payload.page}`,
      method: "get",
      data: payload,
    });
    yield put(entradaesaidaActions.produtosRequestSuccess(res.data));
  } catch (error: any) {
    yield put(entradaesaidaActions.error(formatError(error)));
  }
}

function* produtoRequestWorker({ payload }: PayloadAction<string>) {
  try {
    if (payload === "novo") {
      yield put(entradaesaidaActions.produtoRequestSuccess(novoProduto()));
      return;
    }

    const res: AxiosResponse<Produto> = yield call(apiCall, {
      url: `produtos/${payload}`,
      method: "get",
      data: payload,
    });
    yield put(entradaesaidaActions.produtoRequestSuccess(res.data));
  } catch (error: any) {
    yield put(entradaesaidaActions.error(formatError(error)));
  }
}


function* saveProdutoRequestWorker({ payload }: PayloadAction<Produto>) {
  try {
    const res: AxiosResponse<Produto> = yield call(apiCall, {
      url: `produtos`,
      method: "post",
      data: payload,
    });
    yield put(entradaesaidaActions.saveProdutoRequestSuccess(res.data));
  } catch (error: any) {
    yield put(entradaesaidaActions.error(formatError(error)));
  }
}

function* deleteProdutosRequestWorker({ payload }: PayloadAction<string>) {
  try {
    yield call(apiCall, {
      url: `produtos/${payload}`,
      method: "delete",
      data: payload,
    });
    yield put(entradaesaidaActions.deleteProdutosRequestSuccess());
  } catch (error: any) {
    yield put(entradaesaidaActions.error(formatError(error)));
  }
}


function* sacolasRequestWorker({ payload }: PayloadAction<ListPayload>) {
  try {
    const res: AxiosResponse<ApiPaginationRes<Sacola[]>> = yield call(apiCall, {
      url: `sacolas?termo=${payload.termo}&page=${payload.page}&filtros=${JSON.stringify(payload.filtros)}`,
      method: "get",
      data: payload,
    });
    yield put(entradaesaidaActions.sacolasRequestSuccess(res.data));
  } catch (error: any) {
    yield put(entradaesaidaActions.error(formatError(error)));
  }
}

function* sacolaRequestWorker({ payload }: PayloadAction<string>) {
  try {
    if (payload === "novo") {
      yield put(entradaesaidaActions.sacolaRequestSuccess(novaSacola()));
      return;
    }

    const res: AxiosResponse<Sacola> = yield call(apiCall, {
      url: `sacolas/${payload}?include=itens,itens.produto`,
      method: "get",
      data: payload,
    });
    yield put(entradaesaidaActions.sacolaRequestSuccess(res.data));
  } catch (error: any) {
    yield put(entradaesaidaActions.error(formatError(error)));
  }
}


function* saveSacolaRequestWorker({ payload }: PayloadAction<Sacola>) {
  try {
    const res: AxiosResponse<Sacola> = yield call(apiCall, {
      url: `sacolas?include=itens,itens.produto`,
      method: "post",
      data: payload,
    });
    yield put(entradaesaidaActions.saveSacolaRequestSuccess(res.data));
  } catch (error: any) {
    yield put(entradaesaidaActions.error(formatError(error)));
  }
}

function* deleteSacolaRequestWorker({ payload }: PayloadAction<string>) {
  try {
    yield call(apiCall, {
      url: `sacolas/${payload}`,
      method: "delete",
      data: payload,
    });
    yield put(entradaesaidaActions.deleteSacolaRequestSuccess());
  } catch (error: any) {
    yield put(entradaesaidaActions.error(formatError(error)));
  }
}


function* notasPromissoriasRequestWorker({ payload }: PayloadAction<ListPayload>) {
  try {
    const res: AxiosResponse<ApiPaginationRes<NotaPromissoria[]>> = yield call(apiCall, {
      url: `notas-promissorias?termo=${payload.termo}&page=${payload.page}&filtros=${JSON.stringify(payload.filtros)}`,
      method: "get",
      data: payload,
    });
    yield put(entradaesaidaActions.notasPromissoriasRequestSuccess(res.data));
  } catch (error: any) {
    yield put(entradaesaidaActions.error(formatError(error)));
  }
}

function* notaPromissoriaRequestWorker({ payload }: PayloadAction<string>) {
  try {
    if (payload === "novo") {
      yield put(entradaesaidaActions.notaPromissoriaRequestSuccess(novaNotaPromissoria()));
      return;
    }

    const res: AxiosResponse<NotaPromissoria> = yield call(apiCall, {
      url: `notas-promissorias/${payload}?include=itens,itens.produto,pagamentos,pagamentos.financeiro`,
      method: "get",
      data: payload,
    });
    yield put(entradaesaidaActions.notaPromissoriaRequestSuccess(res.data));
  } catch (error: any) {
    yield put(entradaesaidaActions.error(formatError(error)));
  }
}


function* saveNotaPromissoriaRequestWorker({ payload }: PayloadAction<NotaPromissoria>) {
  try {
    const res: AxiosResponse<NotaPromissoria> = yield call(apiCall, {
      url: `notas-promissorias?include=itens,itens.produto,pagamentos,pagamentos.financeiro`,
      method: "post",
      data: payload,
    });
    yield put(entradaesaidaActions.saveNotaPromissoriaRequestSuccess(res.data));
  } catch (error: any) {
    yield put(entradaesaidaActions.error(formatError(error)));
  }
}

function* deleteNotaPromissoriaRequestWorker({ payload }: PayloadAction<string>) {
  try {
    yield call(apiCall, {
      url: `notas-promissorias/${payload}`,
      method: "delete",
      data: payload,
    });
    yield put(entradaesaidaActions.deleteNotaPromissoriaRequestSuccess());
  } catch (error: any) {
    yield put(entradaesaidaActions.error(formatError(error)));
  }
}

export function* entradaesaidaSaga() {
  yield all([
    takeLatest("entradaesaida/produtoRequest", produtoRequestWorker),
    takeLatest("entradaesaida/saveProdutoRequest", saveProdutoRequestWorker),
    takeLatest("entradaesaida/deleteProdutosRequest", deleteProdutosRequestWorker),
    takeLatest("entradaesaida/produtosRequest", produtosRequestWorker),
    takeLatest("entradaesaida/sacolaRequest", sacolaRequestWorker),
    takeLatest("entradaesaida/saveSacolaRequest", saveSacolaRequestWorker),
    takeLatest("entradaesaida/deleteSacolaRequest", deleteSacolaRequestWorker),
    takeLatest("entradaesaida/sacolasRequest", sacolasRequestWorker),
    takeLatest("entradaesaida/notasPromissoriasRequest", notasPromissoriasRequestWorker),
    takeLatest("entradaesaida/notaPromissoriaRequest", notaPromissoriaRequestWorker),
    takeLatest("entradaesaida/saveNotaPromissoriaRequest", saveNotaPromissoriaRequestWorker),
    takeLatest("entradaesaida/deleteNotaPromissoriaRequest", deleteNotaPromissoriaRequestWorker),
  ]);
}
