import { useIsAuth } from "../../hooks/useIsAuth"
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useState, useEffect, ChangeEvent } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { FiTrash } from "react-icons/fi";
import { cadastroActions } from "../reducer";
import Layout from "../../sistema/components/Layout";
import { ListHeader } from "../../sistema/components/ListHeader";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import Loader from "../../sistema/components/Loader";
import { ListInput } from "../../sistema/components/ListInput";
import ResponsiveTable, { TableHeaders } from "../../sistema/components/ResponsiveTable";
import { Pagination } from "../../sistema/components/Pagination";
import { Pessoa } from "../types/pessoa";
import { ConfirmButton } from "../../sistema/components/ConfirmButton";
import { Select } from "@chakra-ui/react";
import { formatData } from "../../utils/data";

export const PessoaList = () => {
  useIsAuth();

  const { tipo } = useParams();

  const [tipoCadastro, setTipoCadastro] = useState("Clientes");

  useEffect(() => {
    if (tipo == 'clientes') {
      setTipoCadastro('Clientes')
      return;
    }
    setTipoCadastro('Fornecedores')
  }, [tipo, setTipoCadastro])



  const pessoas = useSelector((state: RootState) => state.cadastro.pessoas);
  const listas = useSelector((state: RootState) => state.sistema?.listas);
  const isLoading = useSelector((state: RootState) => !!state.cadastro.isLoading);
  const success = useSelector((state: RootState) => !!state.cadastro.success);
  const dispatch = useDispatch()
  const [page, setPage] = useState(1);
  const [termo, setTermo] = useState("");
  const [filtros, setFiltros] = useState({
    status: '',
    e_cliente: tipo == 'clientes' ? 1 : 0,
    e_fornecedor: tipo == 'clientes' ? 0 : 1,
  })

  useEffect(() => {
    dispatch(cadastroActions.pessoasRequest({
      page,
      termo,
      filtros: {
        ...filtros,
        e_cliente: tipo == 'clientes' ? 1 : 0,
        e_fornecedor: tipo == 'clientes' ? 0 : 1,
      },
    }))
  }, [dispatch, page, termo, filtros, tipo])

  useEffect(() => {
    if (!success) {
      return;
    }
    dispatch(cadastroActions.pessoasRequest({
      page,
      termo,
      filtros,
    }))
  }, [dispatch, success, page, termo, filtros])

  const headers: TableHeaders<Pessoa>[] = [
    {
      label: "Código",
      wrapped: true,
      render: (item) => item.codigo,
    },
    {
      label: "Nome",
      wrapped: false,
      render: (item) => item.nome,
    },
    {
      label: "Endereço",
      wrapped: false,
      render: (item) => `${item.endereco}, n° ${item.numero} - ${item.cidade?.nome}/${item.cidade?.uf}`
    },
    {
      label: "Origem",
      wrapped: true,
      render: (item) => ((listas ?? {})['empresas'] ?? [])?.find((i) => i.value == item.array_empresas)?.label,
    },
    {
      label: "Status",
      wrapped: true,
      render: (item) => item.status,
    },
    {
      label: "Criado em",
      wrapped: true,
      render: (item) => formatData(item.created_at as string, 'dd/MM/yyyy HH:mm'),
    },
    {
      label: "Ações",
      wrapped: true,
      render: (item, index) => <>{<ConfirmButton confirm={() => onDeleteItem(item.id as number)}><FiTrash /></ConfirmButton>}</>,
      notClicable: true,
    },
  ];

  const onDeleteItem = (id: number) => {
    dispatch(cadastroActions.deletePessoaRequest(id));
  }

  const navigate = useNavigate();

  return (
    <Layout>
      <ListHeader label={tipoCadastro}>
        <DefaultButton ml={4} to={`/pessoas/${tipo}/novo`}> Novo </DefaultButton>
      </ListHeader>

      <Loader isLoading={isLoading} />

      <ListInput label="Filtrar por nome, cpf/cnpj, email, cidade ou uf" onSubmit={setTermo}>
        <Select
          onChange={(e) => setFiltros({
            ...filtros,
            status: e.target.value,
          })}
          value={filtros.status}
          borderRadius={2}
          bg={"white"}
        >
          <option value="">Todos</option>
          <option value="Ativo">Ativo</option>
          <option value="Inativo">Inativo</option>
          <option value="Seprocado">Seprocado</option>
        </Select>
      </ListInput>

      {pessoas && <ResponsiveTable isSmall onClick={(item: any) => navigate(`/pessoas/${tipo}/${item.item.id}`)} headers={headers} data={pessoas.data} />}

      {pessoas && <Pagination info={pessoas} paginate={setPage} />}
    </Layout>
  )
}