import { getCurrentData } from "../../utils/data";
import { NotaPromissoriaPagamento } from "../types/nota_promissoria_pagamento";

export const novaNotaPromissoriaPagamento = (): NotaPromissoriaPagamento => {
  return {
    valor: "",
    data_hora: getCurrentData(),
    observacoes: "",
    nota_promissoria_id: "",
    _gera_financeiro: 1,
    tipo_pagamento: 1,
  };
};
