import { Sacola } from "../types/sacola";

export const novaSacola = (): Sacola => {
  return {
    camposPersonalizados: [],
    observacoes: "",
    status: 1,
    pessoa_id: "",
    itens: [],
  };
};
