import React from "react";
import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useField } from "formik";
import SignatureCanvas from 'react-signature-canvas';
import { useState, useEffect } from 'react';

interface IProps {
  name: string;
  label: string;
  width?: string;
}


const InputAssinatura: React.FC<IProps> = ({
  label,
  width,
  ...props
}) => {
  const [field, { value, error }, { setValue }] = useField(props.name);

  const [assData, setAssData] = useState<any>(null);

  const registraAssinatura = () => {
    if (!assData) return;
    if (assData.isEmpty()) {
      if (value) setValue('');
    } else {
      const newData = assData.getTrimmedCanvas().toDataURL('image/png');
      if (value != newData) {
        setValue(newData)
      }
    }
  }


  return (
    <FormControl isInvalid={!!error} mt="0.5em">
      <ButtonGroup mb={1} display={"flex"} alignItems={"center"} gap={2}>
        <Button size={"sm"} onClick={() => { assData && assData.clear(); setValue('') }} colorScheme="blackAlpha">
          Limpar
        </Button>
        {label}
      </ButtonGroup>
      <SignatureCanvas
        onEnd={(evt) => registraAssinatura()}
        canvasProps={{ width: width, height: 300, className: 'sigCanvas', color: 'white', style: { margin: 'auto', backgroundColor: 'white', borderRadius: '10px', border: `2px solid ${error ? 'red' : '#d3d3d3'}` } }} ref={data => setAssData(data)} />
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};

export default InputAssinatura;
