import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import { ListHeader } from './ListHeader';
import { CamposPersonalizadosForm } from './CamposPersonalizadosForm';
import { DefaultButton } from './DefaultButton';

export const CamposPersonalizadosFiltros = ({ par = 'pessoa' }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button onClick={onOpen} colorScheme='blackAlpha'>
        Filtros avançados
      </Button>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"md"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ListHeader label={'Filtros avançados'} />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex width={"full"} wrap={"wrap"}>
              <CamposPersonalizadosForm atualiza={false} retiraOpcaoAdicionarMais={true} tamanho='50%' par={par} idp={""} />
              <Flex width={"full"} my={2} />
              <DefaultButton onClick={onClose} width={"full"}>Voltar</DefaultButton>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}