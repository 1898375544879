import { ListaItem } from "../types/lista_item";

export const novaListaItem = (ordem = 1): ListaItem => {
  return {
    ativo: 1,
    descricao: "",
    ordem,
    lista_id: "",
  };
};
