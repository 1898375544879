import { useState, useEffect, useCallback } from 'react';
import { PessoaCampoPersonalizado } from '../types/pessoa_campo_personalizado';
import { Form, Formik, useField } from 'formik';
import { apiCall } from '../../utils/apiCall';
import InputField from './InputField';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/mainReducer';
import InputSelect from './InputSelect';
import { Flex, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import { CampoPersonalizado } from '../types/campo_personalizado';
import { novoCampoPersonalizado } from '../data/campo_personalizado';
import { mensagemErro } from '../../utils/toasts';
import { formatError } from '../../utils/formatError';
import { Filhos } from './Filhos';
import InputCheck from './InputCheck';
import { DefaultButton } from './DefaultButton';
import validateForm from '../../utils/validateForm';
import { ListHeader } from './ListHeader';
import Loader from './Loader';

export const CamposPersonalizadosForm = ({ par, idp, name = 'camposPersonalizados', qtPorLinha = 4, tamanho = "default", retiraOpcaoAdicionarMais = false, atualiza = true }: { par: string; idp: string | number; name?: string; qtPorLinha?: number; tamanho?: string; retiraOpcaoAdicionarMais?: boolean; atualiza?: boolean; }) => {
  const [, { value }, { setValue }] = useField<PessoaCampoPersonalizado[]>({ name });
  const isMobile = useSelector((state: RootState) => !!state.sistema?.isMobile);

  const larguraInput = () => {
    if (tamanho != 'default') return isMobile ? '100%' : tamanho;
    return isMobile ? '100%' : `${(100 / qtPorLinha).toFixed(2)}%`
  }

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (!idp) {
      const buscaCampos = async () => {
        const { data } = await apiCall({ url: `campos-personalizados/${par}/novo`, method: 'GET' });

        setValue(data);
      }

      if (atualiza || !value) {
        buscaCampos()
      }
    }
  }, [idp, par, setValue])

  const InputLocal = ({ item, index }: { item: PessoaCampoPersonalizado; index: number }) => {
    const [, { value: valueLocal }, { setValue: setValueLocal }] = useField<string>({ name: `${name}[${index}].valor` });

    if (item.tipo.trim() == 'string') {
      return (
        <InputField label={item.nome} name={`${name}[${index}].valor`} width={larguraInput()} />
      )
    }

    const onChangeSelect = (valor: string) => {
      if (valor === 'add_option') {
        setValueLocal(valueLocal, false);
        onOpenModal(item.campo_personalizado_id);
        return;
      }

      setValueLocal(valor, false)
    }

    if (item.tipo.trim() == 'array' || item.tipo.trim() == 'simnao') {
      return (
        <InputSelect noPlaceholer onChangeSetValue={(evt) => onChangeSelect(evt)} label={item.nome} name={`${name}[${index}].valor`} width={larguraInput()}>
          <option selected value="">Selecione...</option>
          {typeof item.array != 'string' && item.array.filter((j) => j.ativo == 1).map((i, ind) => (
            <option value={i.value} key={ind}>{i.label}</option>
          ))}
          <option value="add_option" style={{ backgroundColor: '#a3a3a3', cursor: 'pointer', color: 'white' }}>Adicionar mais uma opção</option>
        </InputSelect>
      )
    }

    if (item.tipo.trim() == 'data') {
      return (
        <InputField label={item.nome} name={`${name}[${index}].valor`} type='date' width={larguraInput()} />
      )
    }

    return (
      <></>
    );
  }

  const MemoizedInputLocal = useCallback(InputLocal, []);

  const [currentItem, setCurrentItem] = useState<CampoPersonalizado | null>(null);
  const [isLoadingLocal, setIsLoadingLocal] = useState(false);

  const onOpenModal = async (id: string | number) => {
    if (id == 'novo') {
      setCurrentItem({
        ...novoCampoPersonalizado(),
        par,
      })
      onOpen()
      return;
    }
    setIsLoadingLocal(true)
    const { data } = await apiCall({ url: `campos-personalizados/${id}`, method: 'GET' });

    setCurrentItem(data);
    setIsLoadingLocal(false)
    onOpen()
  }

  const onSaveModal = async (values: CampoPersonalizado) => {
    try {
      setIsLoadingLocal(true)
      const { data } = await apiCall({
        url: 'campos-personalizados', method: 'POST', data: {
          ...values,
          array: JSON.stringify(values.array)
        }
      });

      const campoEncontradoIndex = (value as PessoaCampoPersonalizado[]).findIndex((i: PessoaCampoPersonalizado) => i.campo_personalizado_id == data.id);

      const { data: camposAtuais } = await apiCall({ url: `campos-personalizados/${par}/${!idp ? 'novo' : idp}`, method: 'GET' });

      const campo = camposAtuais.find((i: PessoaCampoPersonalizado) => i.campo_personalizado_id == data.id);

      const itens = JSON.parse(JSON.stringify(value));

      if (campoEncontradoIndex < 0) {
        itens.push(campo)
      } else {
        itens[campoEncontradoIndex] = campo;
      }
      setValue(itens);
      setIsLoadingLocal(false)
      onClose();
    } catch (err: any) {
      setIsLoadingLocal(false)
      mensagemErro(formatError(err));
    }
  }

  useEffect(() => {
    console.log('alterou')
  }, [value])

  return (
    <>
      <Loader isLoading={isLoadingLocal} />

      {(value ?? []).map((i: PessoaCampoPersonalizado, index: number) => (
        <MemoizedInputLocal item={i} key={`${index}`} index={index} />
      ))}
      <Flex width={"full"} />
      {!retiraOpcaoAdicionarMais && <Link onClick={() => onOpenModal('novo')} px={1}>
        Adicionar campo
      </Link>}

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"md"}>
        <ModalOverlay />
        <ModalContent>
          {!currentItem?.id && <ModalHeader>
            <ListHeader label={currentItem?.id ? 'Editar campo' : 'Criar campo'} />
          </ModalHeader>}
          <ModalCloseButton />
          <ModalBody>

            {currentItem && <Formik
              enableReinitialize
              initialValues={currentItem}
              onSubmit={(val, { setErrors }) => {
                const validation = validateForm({ nome: 'required' }, val)
                if (Object.keys(validation).length > 0) {
                  setErrors(validation)
                  return;
                }

                if (val.tipo.trim() == 'array' && val.array.length == 0) {
                  mensagemErro("Coloque ao menos 1 item na lista de seleção.")
                  return;
                }

                if (val.tipo.trim() == 'simnao' && !val.id) {
                  onSaveModal({
                    ...val,
                    tipo: 'array',
                    array: [
                      {
                        label: 'Sim',
                        value: 'Sim',
                        ativo: 1,
                      },
                      {
                        label: 'Não',
                        value: 'Não',
                        ativo: 1,
                      },
                    ],
                    changed: true,
                  })
                  return;
                }

                onSaveModal({
                  ...val,
                  changed: true,
                })
              }}
            >
              {({ values, submitForm }) => (
                <Form>
                  <Flex width="full" wrap="wrap">
                    {!values.id && <InputField label="Nome do campo" name="nome" width={isMobile ? '100%' : '50%'} />}
                    {!values.id && <InputSelect noPlaceholer={true} label="Tipo do campo" name="tipo" width={isMobile ? '100%' : '50%'}>
                      <option value="string">Texto Livre</option>
                      <option value="array">Lista de Seleção</option>
                      <option value="simnao">Sim ou Não</option>
                      <option value="data">Uma Data</option>
                    </InputSelect>}
                    {values.tipo == 'array' && <Filhos
                      name="array"
                      tituloForm="Itens"
                      editTituloForm="Item"
                      minH="0"
                      novoItemObject={{ label: '', value: '', ativo: 1 }}
                      validation={{ label: 'required' }}
                      headers={[
                        {
                          label: "Descrição",
                          wrapped: false,
                          render: (item) => item?.label,
                        },
                        {
                          label: "Ativo?",
                          wrapped: false,
                          render: (item) => item?.ativo == 1 ? 'Sim' : 'Não',
                        },
                      ]}
                      hasMenuActions={!values.id}
                      form={(values) =>
                        <Flex pb={"10px"} width={"full"} wrap={"wrap"}>
                          <InputField name="label" label="Descrição" width={'100%'} />
                          <InputCheck name="ativo" label="Ativo?" width={'100%'} />
                        </Flex>
                      }
                      formatValuesBeforeSave={(values) => ({ ...values, value: values.label })}
                      afterSave={() => values.id ? submitForm() : {}}
                    />}

                    {!values.id && <Flex pb={4} width={"full"} />}
                    {!values.id && <hr style={{ width: '100%' }} />}

                    {!values.id && <DefaultButton type="submit" mt={4}>Salvar Campo</DefaultButton>}
                  </Flex>
                </Form>
              )}
            </Formik>}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}