import { useIsAuth } from "../../hooks/useIsAuth"
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { FiTrash } from "react-icons/fi";
import { entradaesaidaActions } from "../recucer";
import ResponsiveTable, { TableHeaders } from "../../sistema/components/ResponsiveTable";
import { Produto } from "../types/produto";
import { formatNumber } from "../../utils/formatNumber";
import { ConfirmButton } from "../../sistema/components/ConfirmButton";
import Layout from "../../sistema/components/Layout";
import { ListHeader } from "../../sistema/components/ListHeader";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import Loader from "../../sistema/components/Loader";
import { ListInput } from "../../sistema/components/ListInput";
import { Pagination } from "../../sistema/components/Pagination";
import { CamposPersonalizadosInfoButton } from "../../sistema/components/CamposPersonalizadosInfoButton";
import { ButtonGroup } from "@chakra-ui/react";

export const ProdutosLIst = () => {
  useIsAuth();

  const produtos = useSelector((state: RootState) => state.entradaesaida.produtos);
  const isLoading = useSelector((state: RootState) => !!state.entradaesaida.isLoading);
  const success = useSelector((state: RootState) => !!state.entradaesaida.success);
  const dispatch = useDispatch()
  const [page, setPage] = useState(1);
  const [termo, setTermo] = useState("");

  useEffect(() => {
    dispatch(entradaesaidaActions.produtosRequest({
      page,
      termo,
    }))
  }, [dispatch, page, termo])

  useEffect(() => {
    if (!success) {
      return;
    }
    dispatch(entradaesaidaActions.produtosRequest({
      page,
      termo,
    }))
  }, [dispatch, success, page, termo])

  const headers: TableHeaders<Produto>[] = [
    {
      label: "Id",
      wrapped: true,
      render: (item) => item.id,
    },
    {
      label: "Nome",
      wrapped: true,
      render: (item) => item?.nome,
    },
    {
      label: "Preço",
      wrapped: false,
      render: (item) => formatNumber(item.preco ?? '0', 2),
    },
    {
      label: "Código Barras",
      wrapped: false,
      render: (item) => item.codigo_barras,
    },
    {
      label: "Ativo?",
      wrapped: false,
      render: (item) => item.ativo == 1 ? 'Sim' : 'Não',
    },
    {
      label: "Ações",
      wrapped: true,
      render: (item, index) => <>
        {<ButtonGroup>
          <ConfirmButton confirm={() => onDeleteItem(item.id as number)}><FiTrash /></ConfirmButton>
          <CamposPersonalizadosInfoButton camposPersonalizados={item?.camposPersonalizados ?? []} />
        </ButtonGroup>}
      </>,
      notClicable: true,
    },
  ];

  const onDeleteItem = (id: number) => {
    dispatch(entradaesaidaActions.deleteProdutosRequest(id));
  }

  const navigate = useNavigate();

  return (
    <Layout>
      <ListHeader label="Produtos">
        <DefaultButton ml={4} to="/produtos/novo"> Novo </DefaultButton>
      </ListHeader>

      <Loader isLoading={isLoading} />

      <ListInput label="Filtrar pelo nome ou código de barras" onSubmit={setTermo} />

      {produtos && <ResponsiveTable onClick={(item: any) => navigate(`/produtos/${item.item.id}`)} headers={headers} data={produtos.data} />}

      {produtos && <Pagination info={produtos} paginate={setPage} />}
    </Layout>
  )
}