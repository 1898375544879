import { useIsAuth } from "../../hooks/useIsAuth"
import Layout from "../components/Layout";
import { ListHeader } from "../components/ListHeader";
import { DefaultButton } from "../components/DefaultButton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import ResponsiveTable, { TableHeaders } from "../components/ResponsiveTable";
import { useState, useEffect } from 'react';
import { sistemaActions } from "../recucer";
import { ListInput } from "../components/ListInput";
import { Pagination } from "../components/Pagination";
import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";
import { FiTrash } from "react-icons/fi";
import { ConfirmButton } from "../components/ConfirmButton";
import { Empresa } from "../types/empresa";

export const EmpresaList = () => {
  useIsAuth();

  const empresas = useSelector((state: RootState) => state.sistema.empresas);
  const isLoading = useSelector((state: RootState) => !!state.sistema.isLoading);
  const success = useSelector((state: RootState) => !!state.sistema.success);
  const dispatch = useDispatch()
  const [page, setPage] = useState(1);
  const [termo, setTermo] = useState("");

  useEffect(() => {
    dispatch(sistemaActions.empresasRequest({
      page,
      termo,
    }))
  }, [dispatch, page, termo])

  useEffect(() => {
    if (!success) {
      return;
    }
    dispatch(sistemaActions.empresasRequest({
      page,
      termo,
    }))
  }, [dispatch, success, page, termo])

  const headers: TableHeaders<Empresa>[] = [
    {
      label: "Id",
      wrapped: false,
      render: (item) => item.id,
    },
    {
      label: "CNPJ/CPF",
      wrapped: false,
      render: (item) => item?.cpf_cnpj,
    },
    {
      label: "Nome",
      wrapped: false,
      render: (item) => item?.nome,
    },
    {
      label: "Celular",
      wrapped: false,
      render: (item) => item.celular
    },
    {
      label: "Email",
      wrapped: false,
      render: (item) => item.email
    },
    {
      label: "Ações",
      wrapped: true,
      render: (item, index) => <>{<ConfirmButton confirm={() => onDeleteItem(item.id as number)}><FiTrash /></ConfirmButton>}</>,
      notClicable: true,
    },
  ];

  const onDeleteItem = (id: number) => {
    dispatch(sistemaActions.deleteEmpresaRequest(id));
  }

  const navigate = useNavigate();

  return (
    <Layout>
      <ListHeader label="Empresas">
        <DefaultButton ml={4} to="/empresas/novo"> Novo </DefaultButton>
      </ListHeader>

      <Loader isLoading={isLoading} />

      <ListInput label="Filtrar por empresa" onSubmit={setTermo} />

      {empresas && <ResponsiveTable onClick={(item: any) => navigate(`/empresas/${item.item.id}`)} headers={headers} data={empresas.data} />}

      {empresas && <Pagination info={empresas} paginate={setPage} />}
    </Layout>
  )
}