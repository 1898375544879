import { PessoaContato } from "../types/pessoa_contato";

export const novaPessoaContato = (): PessoaContato => {
  return {
    tipo: "",
    celular: "",
    nome: "",
    observacoes: "",
    pessoa_id: "",
  };
};
