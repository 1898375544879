import { useIsAuth } from "../../hooks/useIsAuth"
import Layout from "../components/Layout";
import { ListHeader } from "../components/ListHeader";
import { DefaultButton } from "../components/DefaultButton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import ResponsiveTable, { TableHeaders } from "../components/ResponsiveTable";
import { useState, useEffect } from 'react';
import { sistemaActions } from "../recucer";
import { ListInput } from "../components/ListInput";
import { Pagination } from "../components/Pagination";
import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";
import { FiTrash } from "react-icons/fi";
import { ConfirmButton } from "../components/ConfirmButton";
import { SistemaAssinatura } from "../types/sistema_assinatura";
import { formatData } from "../../utils/data";

export const AssinaturasList = () => {
  useIsAuth();

  const sistemaAssinaturas = useSelector((state: RootState) => state.sistema.sistemaAssinaturas);
  const isLoading = useSelector((state: RootState) => !!state.sistema.isLoading);
  const success = useSelector((state: RootState) => !!state.sistema.success);
  const dispatch = useDispatch()
  const [page, setPage] = useState(1);
  const [termo, setTermo] = useState("");

  useEffect(() => {
    dispatch(sistemaActions.sistemaAssinaturasRequest({
      page,
      termo,
    }))
  }, [dispatch, page, termo])

  useEffect(() => {
    if (!success) {
      return;
    }
    dispatch(sistemaActions.sistemaAssinaturasRequest({
      page,
      termo,
    }))
  }, [dispatch, success, page, termo])

  const headers: TableHeaders<SistemaAssinatura>[] = [
    {
      label: "Id",
      wrapped: false,
      render: (item) => item.id,
    },
    {
      label: "Empresa CNPJ/CPF",
      wrapped: false,
      render: (item) => item?.empresa?.cpf_cnpj,
    },
    {
      label: "Empresa Nome",
      wrapped: false,
      render: (item) => item?.empresa?.nome,
    },
    {
      label: "Vencimento",
      wrapped: false,
      render: (item) => formatData(item.data_vencimento, "dd/MM/yyyy"),
    },
    {
      label: "Mês",
      wrapped: false,
      render: (item) => item.competencia_mes
    },
    {
      label: "Ano",
      wrapped: false,
      render: (item) => item.competencia_ano
    },
    {
      label: "Pago?",
      wrapped: false,
      render: (item) => item.pago == 1 ? 'Sim' : 'Não',
    },
    {
      label: "Ações",
      wrapped: true,
      render: (item, index) => <>{<ConfirmButton confirm={() => onDeleteItem(item.id as number)}><FiTrash /></ConfirmButton>}</>,
      notClicable: true,
    },
  ];

  const onDeleteItem = (id: number) => {
    dispatch(sistemaActions.deleteSistemaAssinaturaRequest(id));
  }

  const navigate = useNavigate();

  return (
    <Layout>
      <ListHeader label="Assinaturas">
        <DefaultButton ml={4} to="/sistema-assinatura/novo"> Novo </DefaultButton>
      </ListHeader>

      <Loader isLoading={isLoading} />

      <ListInput label="Filtrar por empresa" onSubmit={setTermo} />

      {sistemaAssinaturas && <ResponsiveTable onClick={(item: any) => navigate(`/sistema-assinatura/${item.item.id}`)} headers={headers} data={sistemaAssinaturas.data} />}

      {sistemaAssinaturas && <Pagination info={sistemaAssinaturas} paginate={setPage} />}
    </Layout>
  )
}