// @ts-ignore
import StringMask from "string-mask";

const formatter10 = new StringMask("(AA)AAAA-AAAA");
const formatter11 = new StringMask("(AA)AAAAA-AAAA");

export function formatCelular(celular: string) {
  if (!celular) return '';

  if (celular.trim().length === 10) return formatter10.apply(celular);
  if (celular.trim().length === 11) return formatter11.apply(celular);

  return "";
}
