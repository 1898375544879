import { BobjetoPadrao } from "../../sistema/components/BobjetoPadrao"
import { Pessoa } from "../types/pessoa";

interface IProps {
  width?: string;
  name?: string;
  label?: string;
  onSelectItem?: (item: Pessoa) => void;
}

export const PessoaBobjeto = ({ width = '100%', name = 'pessoa_id', label = 'Pessoa', onSelectItem }: IProps) => {
  const onSelect = (item: Pessoa) => {
    if (typeof onSelectItem !== 'function') return;

    onSelectItem(item);
  }

  return (
    <BobjetoPadrao onSelectItem={onSelect} endpoint="pessoas" label={label} name={name} visualizacao={(item: Pessoa) => (<>{item?.nome} - {item?.status}</>)} width={width} />
  )
}