import {
  Badge,
  Checkbox,
  Table,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";

export type TableHeaders<T> = {
  label: string | React.Component | React.ReactFragment;
  render: (
    item: T,
    index: number
  ) => string | React.Component | React.ReactFragment | ReactNode;
  wrapped?: boolean;
  notClicable?: boolean;
  widthDesktop?: string;
};

export interface ResponsiveTableProps {
  headers: TableHeaders<any>[];
  data: Record<string, any>[];
  onClick?: (item: any) => void;
  tfoot?: ReactNode;
  thead?: React.Component | React.ReactFragment;
  isSmall?: boolean;
  selectedItems?: any;
  changeItems?: (item: any) => void;
  canSelect?: (item: any) => boolean;
  onSelectForm?: (item: any) => ReactNode;
}

const ResponsiveTable: React.FC<ResponsiveTableProps> = ({
  headers,
  data,
  tfoot,
  thead,
  isSmall,
  onClick,
  selectedItems,
  changeItems,
  canSelect,
  onSelectForm,
}) => {

  const isChecked = (item: any) => {
    return (selectedItems ?? []).some((i: any) => i.id == item.id);
  }

  const onChangeItem = (item: any, checked: boolean) => {
    const itens = JSON.parse(JSON.stringify(selectedItems));

    if (checked) {
      itens.push(item);
    } else {
      const itemIndex = itens.findIndex((i: any) => i.id == item.id);
      itens.splice(itemIndex, 1);
    }

    typeof changeItems === 'function' && changeItems(itens);
  }

  const isCheckedAll = () => {
    return (data ?? []).every((i) => {
      if (typeof canSelect === 'function' && !canSelect(i)) return true;

      return (selectedItems ?? []).some((j: any) => j.id == i.id);
    })
  }

  const onChangeAllItems = (checked: boolean) => {
    if (checked) {
      typeof changeItems === 'function' && changeItems(data.filter((i) => typeof canSelect === 'function' ? canSelect(i) : true));
      return;
    }

    typeof changeItems === 'function' && changeItems([]);
  }

  return (
    <Table variant="striped" colorScheme="blackAlpha" size={isSmall ? "sm" : "md"}>
      <Thead display={["none", "table-header-group"]}>
        <>
          <Tr>
            {selectedItems && <Th>
              <Checkbox bg={"white"} size={"lg"} onChange={(evt) => onChangeAllItems(evt.target.checked)} isChecked={isCheckedAll()} />
            </Th>}
            {headers.map((header, i) => (
              <Th key={`${i}`}>{header.label as string}</Th>
            ))}
          </Tr>
          {thead}
        </>
      </Thead>
      <Tbody>
        {!!data &&
          data.map((item, i) => (
            <>
              <Tr key={`${i}`}>
                {selectedItems && <Td cursor={"default"}
                  whiteSpace="nowrap"
                  display={["block", "table-cell"]}>
                  <Checkbox disabled={typeof canSelect === 'function' ? !canSelect(item) : false} bg={"white"} size={"lg"} onChange={(evt) => onChangeItem(item, evt.target.checked)} isChecked={isChecked(item)} />
                </Td>}

                {headers.map((header, j) =>
                  header.wrapped ? (
                    <Td
                      onClick={header.notClicable ? () => { } : () => typeof onClick === 'function' && onClick({
                        item,
                        index: i,
                      })}
                      cursor={header.notClicable ? "default" : "pointer"}
                      key={`${j}`}
                      whiteSpace="nowrap"
                      width={header.widthDesktop ? header.widthDesktop : ["100%", "1%"]}
                      display={["block", "table-cell"]}
                    >
                      <>
                        <Badge display={["inline", "none"]} colorScheme="green">
                          {header.label as string}
                        </Badge>
                        {header.render(item, i)}
                      </>
                    </Td>
                  ) : (
                    <Td
                      onClick={header.notClicable ? () => { } : () => typeof onClick === 'function' && onClick({
                        item,
                        index: i,
                      })}
                      cursor={header.notClicable ? "default" : "pointer"}
                      key={`${j}`}
                      width={header.widthDesktop ? header.widthDesktop : "100%"}
                      display={["block", "table-cell"]}
                    >
                      <>
                        <Badge display={["inline", "none"]} colorScheme="green">
                          {header.label as string}
                        </Badge>
                        {header.render(item, i)}
                      </>
                    </Td>
                  )
                )}
              </Tr>
              {isChecked(item) && typeof onSelectForm === 'function' && onSelectForm(item)}
            </>
          ))}
      </Tbody>
      {!!tfoot && <Tfoot>{tfoot}</Tfoot>}
    </Table>
  );
};

export default ResponsiveTable;
