import { Flex, Text } from "@chakra-ui/react";
import { Form, Formik } from 'formik';
import { DefaultButton } from "./DefaultButton";
import InputField from "./InputField";
import { FiSearch } from "react-icons/fi";
import { ReactNode } from "react";

interface IProps {
  onSubmit: (val: string) => void;
  label: string;
  children?: ReactNode;
}

export const ListInput = ({ onSubmit, label, children }: IProps) => {
  return (
    <Formik
      initialValues={{ termo: "" }}
      onSubmit={(values) => {
        onSubmit(values.termo);
      }}
    >
      <Form>
        <Flex width={"full"} wrap={'wrap'} justifyContent={'space-between'}>
          <Flex my={4} width={{ base: 'full', md: '60%' }}>
            <InputField noPadding={true} placeholder={label} noBorder={true} name="termo" type="text" width="full" />

            <DefaultButton type="submit" borderRadius="0px 10px 10px 0"><FiSearch fontSize="30px" /> <Text ml={2}>Filtrar</Text></DefaultButton>
          </Flex>
          <Flex my={4}>
            {children}
          </Flex>
        </Flex>
      </Form>
    </Formik>
  )
}