import { toast } from "react-toastify";

export const mensagemErro = (mensagem: string, tempo = 5000) => {
  toast.error(mensagem, {
    position: "top-center",
    autoClose: tempo,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    });
}

export const mensagemSucesso = (mensagem: string, tempo = 5000) => {
  toast.success(mensagem, {
    position: "top-center",
    autoClose: tempo,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    });
}