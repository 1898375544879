export const arrayFormaContato = () => {
  return [
    {
      name: "Celular",
    },
    {
      name: "Telefone",
    },
    {
      name: "Email",
    },
    {
      name: "WhatsApp",
    },
    {
      name: "Outros",
    },
  ];
};
