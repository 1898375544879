import { BobjetoPadrao } from "../../sistema/components/BobjetoPadrao"
import { formatValor } from "../../utils/formatValor";
import { Produto } from "../types/produto";

interface IProps {
  width?: string;
  name?: string;
  label?: string;
  onSelectItem?: (item: Produto) => void;
}

export const ProdutoBobjeto = ({ width = '100%', name = 'produto_id', label = 'Produto', onSelectItem }: IProps) => {
  const onSelect = (item: Produto) => {
    if (typeof onSelectItem !== 'function') return;

    onSelectItem(item);
  }

  return (
    <BobjetoPadrao onSelectItem={onSelect} endpoint="produtos" label={label} name={name} visualizacao={(item: Produto) => (<>{item?.nome} - {formatValor(item?.preco, 2)}</>)} width={width} />
  )
}