import { NotaPromissoria } from "../types/nota_promissoria";

export const novaNotaPromissoria = (): NotaPromissoria => {
  return {
    numero: "",
    observacoes: "",
    status: 1,
    pessoa_id: "",
    itens: [],
    pagamentos: [],
    assinatura: "",
  };
};
