import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useIsAuth } from "../../hooks/useIsAuth"
import { useParams } from "react-router-dom";
import { useEffect } from 'react'
import { cadastroActions } from "../reducer";
import Layout from "../../sistema/components/Layout";
import { ListHeader } from "../../sistema/components/ListHeader";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import Loader from "../../sistema/components/Loader";
import InputField from "../../sistema/components/InputField";
import { Form, Formik } from "formik";
import { Box, Flex } from "@chakra-ui/react";
import validateForm from "../../utils/validateForm";
import { ButtonHistorico } from "../../sistema/components/ButtonHistorico";
import { Filhos } from "../../sistema/components/Filhos";
import { novaListaItem } from "../data/lista_item";
import InputNumber from "../../sistema/components/InputNumber";
import InputCheck from "../../sistema/components/InputCheck";

export const ListaEdit = () => {
  useIsAuth();

  const { id } = useParams();

  const lista = useSelector((state: RootState) => state.cadastro.lista)
  const isLoading = useSelector((state: RootState) => !!state.cadastro.isLoading)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!id) return;

    dispatch(cadastroActions.listaRequest(id))
  }, [dispatch, id])

  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);

  const getLarguraPagina = (partes: number) => {
    if (isMobile) return '100%';

    return `${100 / partes}%`;
  }

  return (
    <Layout>
      <ListHeader label={'Lista'}>
        <DefaultButton ml={4} to={`/listas`}> Voltar </DefaultButton>
      </ListHeader>

      <Loader isLoading={isLoading} />

      {lista && <Formik
        enableReinitialize
        initialValues={lista}
        onSubmit={(val, { setErrors }) => {
          const validation = validateForm({ codigo: 'required', descricao: 'required' }, val)
          if (Object.keys(validation).length > 0) {
            setErrors(validation)
            return;
          }

          dispatch(cadastroActions.saveListaRequest({
            ...val,
            changed: true,
          }));
        }}
      >
        {({ values }) => (
          <Form>
            <Flex width="full" wrap="wrap">
              <InputField label="Código" name="codigo" width={`50%`} />
              <InputField label="Descrição" name="descricao" width={`50%`} />

              <Filhos
                name="itens"
                tituloForm="Itens"
                editTituloForm="Item"
                novoItemObject={novaListaItem(values?.itens?.length + 1)}
                validation={{ ordem: 'required', descricao: 'required' }}
                headers={[
                  {
                    label: "Ordem",
                    wrapped: false,
                    render: (item) => item?.ordem,
                  },
                  {
                    label: "Descrição",
                    wrapped: false,
                    render: (item) => item?.descricao,
                  },
                  {
                    label: "Ativo?",
                    wrapped: false,
                    render: (item) => item?.ativo == 1 ? 'Sim' : 'Não',
                  },
                ]}
                form={(values) =>
                  <Flex pb={"150px"} width={"full"} wrap={"wrap"}>
                    <InputNumber decimalPlaces={0} name="ordem" label="Ordem" width={getLarguraPagina(2)} />
                    <InputField name="descricao" label="Descrição" width={getLarguraPagina(2)} />
                    <InputCheck name="ativo" label="Ativo?" width={'100%'} />
                  </Flex>
                }
              />
              <Flex width={"full"} />
              <Box
                bg={"white"}
                mt={2}
                width={"full"}
                position="sticky"
                bottom="10px"
                zIndex="999"
                p={2}
              >
                <DefaultButton type="submit">Salvar lista</DefaultButton>
                {values.id && <ButtonHistorico par="lista" idpar={values.id} ml={2} />}
              </Box>
            </Flex>
          </Form>
        )}
      </Formik>}
    </Layout>
  )
}