import { Button, ButtonProps, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spacer, useDisclosure } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { sistemaActions } from "../recucer";
import { RootState } from "../../app/mainReducer";
import { ListHeaderSecondary } from "./ListHeaderSecondary";
import ResponsiveTable, { TableHeaders } from "./ResponsiveTable";
import { Historico } from "../types/historico";
import { formatData } from "../../utils/data";
import Loader from "./Loader";

interface IProps extends ButtonProps {
  par: string;
  idpar: any;
}

export const ButtonHistorico = ({ par, idpar, ...props }: IProps) => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const historico = useSelector((state: RootState) => state.sistema?.historico ?? [])
  const isLoading = useSelector((state: RootState) => !!state.sistema?.isLoading)

  const onOpenHistorico = () => {
    dispatch(sistemaActions.historicoRequest({
      par,
      idpar,
    }))
    onOpen()
  }

  const headers: TableHeaders<Historico>[] = [
    {
      label: "Descrição",
      wrapped: false,
      render: (item) => item.descricao,
    },
    {
      label: "Usuário",
      wrapped: false,
      render: (item) => item.usuario?.name
    },
    {
      label: "Data",
      wrapped: true,
      render: (item) => formatData(item.data, 'dd/MM/yyy HH:mm')
    },
  ];

  return (
    <>
      <Loader isLoading={isLoading} />
      <Button {...props} colorScheme="blackAlpha" onClick={onOpenHistorico}>Histórico</Button>
      {!isLoading && <Modal size={"2xl"} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ListHeaderSecondary label="Histórico" />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {historico && <ResponsiveTable isSmall headers={headers} data={historico} />}
            <Spacer marginY={5} />
            <Button colorScheme="blackAlpha" onClick={onClose}>Fechar</Button>
          </ModalBody>
        </ModalContent>
      </Modal>}
    </>
  )
}