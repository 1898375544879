import { Box, CircularProgress, Flex, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";

const Loader = ({ isLoading }: { isLoading: boolean }) => {
  const cor_primaria = useSelector((state: RootState) => state.sistema?.parametros?.cor_primaria ? state.sistema?.parametros?.cor_primaria : state.sistema.cor_primaria)

  return (
    <>{isLoading && <Box
      position="fixed"
      top={0}
      left={0}
      right={0}
      bottom={0}
      zIndex="9999"
      display="flex"
      alignItems="center"
      justifyContent="center"
      backgroundColor="rgba(0, 0, 0, 0.7)"
    >
      <Flex direction='column' alignItems={"center"}>
        <CircularProgress isIndeterminate color={cor_primaria} />
        <Text color="white" mt={4}>
          Carregando...
        </Text>
      </Flex>
    </Box>}
    </>
  );
};

export default Loader;