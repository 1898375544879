import { Button, ButtonGroup } from "@chakra-ui/react";
import { useState } from "react";
import { FiTrash } from "react-icons/fi";

export const DeleteButton = ({ confirmou, isLoading = false }: { confirmou: () => void; isLoading?: boolean; }) => {
  const [abriu, setAbriu] = useState(false);

  if (abriu) {
    return (
      <ButtonGroup bgColor={"yellow.50"} p={1} borderRadius={"10px"}>
        <Button onClick={() => setAbriu(false)} size={"sm"} colorScheme="blackAlpha">
          Cancelar
        </Button>
        <Button onClick={() => { setAbriu(false); confirmou(); }} size={"sm"} colorScheme="red">
          Confirmar
        </Button>
      </ButtonGroup>
    )
  }

  return (
    <Button isLoading={isLoading} onClick={() => setAbriu(true)} title="Apagar parcela" size={"sm"} colorScheme="red" p={2}>
      <FiTrash />
    </Button>
  )
}
