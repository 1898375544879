import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { Alert, AlertIcon, AlertTitle, Button, Flex, Heading, Img } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { sistemaActions } from "../recucer";
import InputField from "../components/InputField";
import validateForm from "../../utils/validateForm";
import logoPadrao from "../../../images/logoPadrao.png";
import { APIURL } from "../../utils/apiCall";

export const Login = () => {
  const navigate = useNavigate();
  const isLogged = useSelector((state: RootState) => !!state.sistema.token);
  const error = useSelector((state: RootState) => state.sistema.error);
  const isLoggingIn = useSelector(
    (state: RootState) => state.sistema.isLoggingIn
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(sistemaActions.parametrosRequest())
  }, [dispatch]);

  useEffect(() => {
    if (isLogged) {
      navigate("/");
    }
  }, [isLogged, navigate]);

  const cor_primaria = useSelector((state: RootState) => state.sistema?.parametros?.cor_primaria ? state.sistema?.parametros?.cor_primaria : state.sistema.cor_primaria)
  const parametros = useSelector((state: RootState) => state.sistema?.parametros)

  return (
    <Flex maxW="30rem" margin="auto" direction="column" mt={10}>
      <Img mx="auto" height='170px' src={parametros?.logo ? APIURL.replace('/api', '/storage/images/') + parametros.logo : logoPadrao} />

      {error && (
        <Alert status="error" mt="2rem">
          <AlertIcon />
          <AlertTitle>{error}</AlertTitle>
        </Alert>
      )}
      <Flex
        direction="column"
        bgColor={cor_primaria}
        color="white"
        px="1rem"
        marginTop="1rem"
        borderRadius="3px"
      >
        <Heading py={8} mx="auto" textAlign={"center"}>Sistema {parametros?.nome_empresa ? parametros?.nome_empresa : 'AG Solution'}</Heading>
        <Formik
          initialValues={{ email: "", password: "" }}
          onSubmit={(values, { setErrors }) => {
            const validation = validateForm({
              'email': 'required',
              'password': 'required',
            }, values);

            if (Object.keys(validation).length > 0) {
              setErrors(validation)
              return;
            }
            console.log("login form", values);
            dispatch(sistemaActions.loginRequest(values));
          }}
        >
          <Form>
            <InputField label="E-mail" name="email" autoFocus type="email" />
            <InputField label="Senha" name="password" type="password" />
            <Button my={4} type="submit" isLoading={isLoggingIn} size="lg" width="full" colorScheme="blackAlpha" mt="1rem">Entrar</Button>
          </Form>
        </Formik>
      </Flex>
    </Flex>
  )
}