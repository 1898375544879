import { PayloadAction } from "@reduxjs/toolkit";
import { put, all, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { call } from "redux-saga/effects";
import { apiCall } from "../utils/apiCall";
import { formatError } from "../utils/formatError";
import { Pessoa } from "./types/pessoa";
import { ListPayload } from "../sistema/types/listPayload";
import { cadastroActions } from "./reducer";
import { ApiPaginationRes } from "../sistema/types/apiPaginationRes";
import { novaPessoa } from "./data/pessoa";
import { TabelaPreco } from "./types/tabela_preco";
import { novaTabelaPreco } from "./data/tabela_preco";
import { Cidade } from "./types/cidade";
import { novaCidade } from "./data/cidade";
import { novaRota } from "./data/rota";
import { Rota } from "./types/rota";
import { Lista } from "./types/lista";
import { novaLista } from "./data/lista";

function* savePessoaRequestWorker({ payload }: PayloadAction<Pessoa>) {
  try {
    const res: AxiosResponse<Pessoa> = yield call(apiCall, {
      url: `pessoas?include=contatos&append=camposPersonalizados`,
      method: "post",
      data: payload,
    });
    yield put(cadastroActions.savePessoaRequestSuccess(res.data));
  } catch (error: any) {
    yield put(cadastroActions.error(formatError(error)));
  }
}
function* deletePessoaRequestWorker({ payload }: PayloadAction<string>) {
  try {
    yield call(apiCall, {
      url: `pessoas/${payload}`,
      method: "delete",
      data: payload,
    });
    yield put(cadastroActions.deletePessoaRequestSuccess());
  } catch (error: any) {
    yield put(cadastroActions.error(formatError(error)));
  }
}

function* pessoasRequestWorker({ payload }: PayloadAction<ListPayload>) {
  try {
    const res: AxiosResponse<ApiPaginationRes<Pessoa[]>> = yield call(apiCall, {
      url: `pessoas?termo=${payload.termo}&page=${
        payload.page
      }&filtros=${JSON.stringify(payload.filtros)}`,
      method: "get",
      data: payload,
    });
    yield put(cadastroActions.pessoasRequestSuccess(res.data));
  } catch (error: any) {
    yield put(cadastroActions.error(formatError(error)));
  }
}

function* pessoaRequestWorker({ payload }: PayloadAction<string>) {
  try {
    if (payload === "novo") {
      yield put(cadastroActions.pessoaRequestSuccess(novaPessoa()));
      return;
    }

    const res: AxiosResponse<Pessoa> = yield call(apiCall, {
      url: `pessoas/${payload}?include=contatos&append=camposPersonalizados`,
      method: "get",
      data: payload,
    });
    yield put(cadastroActions.pessoaRequestSuccess(res.data));
  } catch (error: any) {
    yield put(cadastroActions.error(formatError(error)));
  }
}

function* saveTabelaRequestWorker({ payload }: PayloadAction<TabelaPreco>) {
  try {
    const res: AxiosResponse<TabelaPreco> = yield call(apiCall, {
      url: `tabelas`,
      method: "post",
      data: {
        ...payload,
        itens: JSON.stringify(payload.itens),
      },
    });
    yield put(cadastroActions.saveTabelaRequestSuccess(res.data));
  } catch (error: any) {
    yield put(cadastroActions.error(formatError(error)));
  }
}
function* deleteTabelaRequestWorker({ payload }: PayloadAction<string>) {
  try {
    yield call(apiCall, {
      url: `tabelas/${payload}`,
      method: "delete",
      data: payload,
    });
    yield put(cadastroActions.deleteTabelaRequestSuccess());
  } catch (error: any) {
    yield put(cadastroActions.error(formatError(error)));
  }
}

function* tabelasRequestWorker({ payload }: PayloadAction<ListPayload>) {
  try {
    const res: AxiosResponse<ApiPaginationRes<TabelaPreco[]>> = yield call(
      apiCall,
      {
        url: `tabelas?termo=${payload.termo}&page=${payload.page}`,
        method: "get",
        data: payload,
      }
    );
    yield put(cadastroActions.tabelasRequestSuccess(res.data));
  } catch (error: any) {
    yield put(cadastroActions.error(formatError(error)));
  }
}

function* tabelaRequestWorker({ payload }: PayloadAction<string>) {
  try {
    if (payload === "novo") {
      yield put(cadastroActions.tabelaRequestSuccess(novaTabelaPreco()));
      return;
    }

    const res: AxiosResponse<TabelaPreco> = yield call(apiCall, {
      url: `tabelas/${payload}`,
      method: "get",
      data: payload,
    });
    yield put(cadastroActions.tabelaRequestSuccess(res.data));
  } catch (error: any) {
    yield put(cadastroActions.error(formatError(error)));
  }
}

function* saveCidadeRequestWorker({ payload }: PayloadAction<Cidade>) {
  try {
    const res: AxiosResponse<Cidade> = yield call(apiCall, {
      url: `cidades`,
      method: "post",
      data: payload,
    });
    yield put(cadastroActions.saveCidadeRequestSuccess(res.data));
  } catch (error: any) {
    yield put(cadastroActions.error(formatError(error)));
  }
}
function* deleteCidadeRequestWorker({ payload }: PayloadAction<string>) {
  try {
    yield call(apiCall, {
      url: `cidades/${payload}`,
      method: "delete",
      data: payload,
    });
    yield put(cadastroActions.deleteCidadeRequestSuccess());
  } catch (error: any) {
    yield put(cadastroActions.error(formatError(error)));
  }
}

function* cidadesRequestWorker({ payload }: PayloadAction<ListPayload>) {
  try {
    const res: AxiosResponse<ApiPaginationRes<Cidade[]>> = yield call(apiCall, {
      url: `cidades?termo=${payload.termo}&page=${
        payload.page
      }&filtros=${JSON.stringify(payload.filtros)}`,
      method: "get",
      data: payload,
    });
    yield put(cadastroActions.cidadesRequestSuccess(res.data));
  } catch (error: any) {
    yield put(cadastroActions.error(formatError(error)));
  }
}

function* cidadeRequestWorker({ payload }: PayloadAction<string>) {
  try {
    if (payload === "novo") {
      yield put(cadastroActions.cidadeRequestSuccess(novaCidade()));
      return;
    }

    const res: AxiosResponse<Cidade> = yield call(apiCall, {
      url: `cidades/${payload}`,
      method: "get",
      data: payload,
    });
    yield put(cadastroActions.cidadeRequestSuccess(res.data));
  } catch (error: any) {
    yield put(cadastroActions.error(formatError(error)));
  }
}



function* saveRotaRequestWorker({ payload }: PayloadAction<Rota>) {
  try {
    const res: AxiosResponse<Rota> = yield call(apiCall, {
      url: `rotas?include=cidades,cidades.cidade`,
      method: "post",
      data: payload,
    });
    yield put(cadastroActions.saveRotaRequestSuccess(res.data));
  } catch (error: any) {
    yield put(cadastroActions.error(formatError(error)));
  }
}
function* deleteRotaRequestWorker({ payload }: PayloadAction<string>) {
  try {
    yield call(apiCall, {
      url: `rotas/${payload}`,
      method: "delete",
      data: payload,
    });
    yield put(cadastroActions.deleteRotaRequestSuccess());
  } catch (error: any) {
    yield put(cadastroActions.error(formatError(error)));
  }
}

function* rotasRequestWorker({ payload }: PayloadAction<ListPayload>) {
  try {
    const res: AxiosResponse<ApiPaginationRes<Rota[]>> = yield call(apiCall, {
      url: `rotas?termo=${payload.termo}&page=${
        payload.page
      }&filtros=${JSON.stringify(payload.filtros)}`,
      method: "get",
      data: payload,
    });
    yield put(cadastroActions.rotasRequestSuccess(res.data));
  } catch (error: any) {
    yield put(cadastroActions.error(formatError(error)));
  }
}

function* rotaRequestWorker({ payload }: PayloadAction<string>) {
  try {
    if (payload === "novo") {
      yield put(cadastroActions.rotaRequestSuccess(novaRota()));
      return;
    }

    const res: AxiosResponse<Rota> = yield call(apiCall, {
      url: `rotas/${payload}?include=cidades,cidades.cidade`,
      method: "get",
      data: payload,
    });
    yield put(cadastroActions.rotaRequestSuccess(res.data));
  } catch (error: any) {
    yield put(cadastroActions.error(formatError(error)));
  }
}


function* saveListaRequestWorker({ payload }: PayloadAction<Lista>) {
  try {
    const res: AxiosResponse<Lista> = yield call(apiCall, {
      url: `listas?include=itens`,
      method: "post",
      data: payload,
    });
    yield put(cadastroActions.saveListaRequestSuccess(res.data));
  } catch (error: any) {
    yield put(cadastroActions.error(formatError(error)));
  }
}
function* deleteListaRequestWorker({ payload }: PayloadAction<string>) {
  try {
    yield call(apiCall, {
      url: `listas/${payload}`,
      method: "delete",
      data: payload,
    });
    yield put(cadastroActions.deleteListaRequestSuccess());
  } catch (error: any) {
    yield put(cadastroActions.error(formatError(error)));
  }
}

function* listasRequestWorker({ payload }: PayloadAction<ListPayload>) {
  try {
    const res: AxiosResponse<ApiPaginationRes<Lista[]>> = yield call(apiCall, {
      url: `listas?termo=${payload.termo}&page=${
        payload.page
      }&filtros=${JSON.stringify(payload.filtros)}`,
      method: "get",
      data: payload,
    });
    yield put(cadastroActions.listasRequestSuccess(res.data));
  } catch (error: any) {
    yield put(cadastroActions.error(formatError(error)));
  }
}

function* listaRequestWorker({ payload }: PayloadAction<string>) {
  try {
    if (payload === "novo") {
      yield put(cadastroActions.listaRequestSuccess(novaLista()));
      return;
    }

    const res: AxiosResponse<Lista> = yield call(apiCall, {
      url: `listas/${payload}?include=itens`,
      method: "get",
      data: payload,
    });
    yield put(cadastroActions.listaRequestSuccess(res.data));
  } catch (error: any) {
    yield put(cadastroActions.error(formatError(error)));
  }
}

export function* cadastroSaga() {
  yield all([
    takeLatest("cadastro/pessoasRequest", pessoasRequestWorker),
    takeLatest("cadastro/savePessoaRequest", savePessoaRequestWorker),
    takeLatest("cadastro/deletePessoaRequest", deletePessoaRequestWorker),
    takeLatest("cadastro/pessoaRequest", pessoaRequestWorker),
    takeLatest("cadastro/tabelasRequest", tabelasRequestWorker),
    takeLatest("cadastro/saveTabelaRequest", saveTabelaRequestWorker),
    takeLatest("cadastro/deleteTabelaRequest", deleteTabelaRequestWorker),
    takeLatest("cadastro/tabelaRequest", tabelaRequestWorker),
    takeLatest("cadastro/cidadesRequest", cidadesRequestWorker),
    takeLatest("cadastro/saveCidadeRequest", saveCidadeRequestWorker),
    takeLatest("cadastro/deleteCidadeRequest", deleteCidadeRequestWorker),
    takeLatest("cadastro/cidadeRequest", cidadeRequestWorker),
    takeLatest("cadastro/rotasRequest", rotasRequestWorker),
    takeLatest("cadastro/saveRotaRequest", saveRotaRequestWorker),
    takeLatest("cadastro/deleteRotaRequest", deleteRotaRequestWorker),
    takeLatest("cadastro/rotaRequest", rotaRequestWorker),
    takeLatest("cadastro/listasRequest", listasRequestWorker),
    takeLatest("cadastro/saveListaRequest", saveListaRequestWorker),
    takeLatest("cadastro/deleteListaRequest", deleteListaRequestWorker),
    takeLatest("cadastro/listaRequest", listaRequestWorker),
  ]);
}
