import { combineReducers, configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { createLogger } from "redux-logger";
import { mainSaga } from "./mainSaga";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import sistemaSlice from "../sistema/recucer";
import cadastroSlice from "../cadastros/reducer";
import crmSlice from "../crm/reducer";
import entradaesaidaSlice from "../entradaesaida/recucer";
import financeiroSlice from "../financeiro/reducer";

const sagaMiddleware = createSagaMiddleware();
const logger = createLogger();
const persistConfig = {
  key: "agsolution_app",
  storage,
};
const rootReducer = combineReducers({
  sistema: sistemaSlice.reducer,
  cadastro: cadastroSlice.reducer,
  crm: crmSlice.reducer,
  entradaesaida: entradaesaidaSlice.reducer,
  financeiro: financeiroSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

let middlewares: any = [sagaMiddleware];
if (process.env.NODE_ENV === "development") {
  middlewares = [...middlewares, logger];
}

export const store = configureStore({
  reducer: persistedReducer,
  middleware: middlewares,
});
export const persistor = persistStore(store);

sagaMiddleware.run(mainSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
