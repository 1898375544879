import { getCurrentData } from "../../utils/data";
import { Agendamento } from "../types/agendamento";

export const novoAgendamento = (): Agendamento => {
  return {
    data: getCurrentData(),
    pessoa_id: "",
    descricao: "",
    quantidade_notificado: 0,
    status: 0,
    criador: "",
    finalizado: 0,
  };
};
