import { Produto } from "../types/produto";

export const novoProduto = (): Produto => {
  return {
    nome: '',
    preco: '0',
    codigo_barras: '',
    ativo: 1,
    camposPersonalizados: [],
  }
}