import { getCurrentData } from "../../utils/data";
import { Pedido } from "../types/pedido";

export const novoPedido = (): Pedido => {
  return {
    numero: "",
    data: getCurrentData(),
    pessoa_id: "",
    valor_total: "0",
    cancelado: 0,
    faturado: 0,
    array_tipo_pedido: 0,
    itens: [],
  };
};
