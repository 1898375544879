import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useIsAuth } from "../../hooks/useIsAuth"
import { useParams } from "react-router-dom";
import { useEffect } from 'react'
import Layout from "../../sistema/components/Layout";
import { ListHeader } from "../../sistema/components/ListHeader";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import Loader from "../../sistema/components/Loader";
import InputField from "../../sistema/components/InputField";
import { Form, Formik } from "formik";
import { Box, Flex } from "@chakra-ui/react";
import validateForm from "../../utils/validateForm";
import { ButtonHistorico } from "../../sistema/components/ButtonHistorico";
import { entradaesaidaActions } from "../recucer";
import InputNumber from "../../sistema/components/InputNumber";
import InputCheck from "../../sistema/components/InputCheck";
import { CamposPersonalizadosForm } from "../../sistema/components/CamposPersonalizadosForm";

export const ProdutosEdit = () => {
  useIsAuth();

  const { id } = useParams();

  const produto = useSelector((state: RootState) => state.entradaesaida.produto)
  const isLoading = useSelector((state: RootState) => !!state.entradaesaida.isLoading)
  const isMobile = useSelector((state: RootState) => !!state.sistema.isMobile)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!id) return;

    dispatch(entradaesaidaActions.produtoRequest(id))
  }, [dispatch, id])

  const w_1_3 = isMobile ? '100%' : '33.33%';

  return (
    <Layout>
      <ListHeader label={'Produto'}>
        <DefaultButton ml={4} to={`/produtos`}> Voltar </DefaultButton>
      </ListHeader>

      <Loader isLoading={isLoading} />

      {produto && <Formik
        enableReinitialize
        initialValues={produto}
        onSubmit={(val, { setErrors }) => {
          const validation = validateForm({ nome: 'required' }, val)
          if (Object.keys(validation).length > 0) {
            setErrors(validation)
            return;
          }

          dispatch(entradaesaidaActions.saveProdutoRequest({
            ...val,
            changed: true,
          }));
        }}
      >
        {({ values }) => (
          <Form>
            <Flex width="full" wrap="wrap">
              <InputField label="Nome" name="nome" width={w_1_3} />
              <InputNumber label="Preço" name="preco" width={w_1_3} />
              <InputField label="Código de barras" name="codigo_barras" width={w_1_3} />
              <InputCheck label="Ativo?" name="ativo" width={"full"} />

              <CamposPersonalizadosForm par="produto" idp={produto?.id as number} />

              <Flex width={"full"} />
              <Box
                bg={"white"}
                mt={2}
                width={"full"}
                position="sticky"
                bottom="10px"
                zIndex="999"
                p={2}
              >
                <DefaultButton type="submit">Salvar produto</DefaultButton>
                {values.id && <ButtonHistorico par="produto" idpar={values.id} ml={2} />}
              </Box>
            </Flex>
          </Form>
        )}
      </Formik>}
    </Layout>
  )
}