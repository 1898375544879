import { useIsAuth } from "../../hooks/useIsAuth"
import Layout from "../components/Layout";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import ResponsiveTable, { TableHeaders } from "../components/ResponsiveTable";
import { useState, useEffect } from 'react';
import { sistemaActions } from "../recucer";
import { ListInput } from "../components/ListInput";
import { Pagination } from "../components/Pagination";
import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";
import { ImportadorPessoa } from "../types/importador_pessoa";
import { ListHeader } from "../components/ListHeader";
import { DefaultButton } from "../components/DefaultButton";
import { Button, Flex, Input, Select } from "@chakra-ui/react";
import * as XLSX from 'xlsx';
import { retornaApenasNumero } from "../../utils/formatString";
import { apiCall } from "../../utils/apiCall";
import { ShowArray } from "../components/ShowArray";

export const ImportadorPessoaList = () => {
  useIsAuth();

  const importadorPessoas = useSelector((state: RootState) => state.sistema.importadorPessoas);
  const isLoading = useSelector((state: RootState) => !!state.sistema.isLoading);
  const success = useSelector((state: RootState) => !!state.sistema.success);
  const dispatch = useDispatch()
  const [page, setPage] = useState(1);
  const [termo, setTermo] = useState("");
  const [filtros, setFiltros] = useState({
    status: '',
  })

  useEffect(() => {
    dispatch(sistemaActions.importadorPessoas({
      page,
      termo,
      filtros,
    }))
  }, [dispatch, page, termo, filtros])

  useEffect(() => {
    if (!success) {
      return;
    }
    dispatch(sistemaActions.importadorPessoas({
      page,
      termo,
    }))
  }, [dispatch, success, page, termo])

  const headers: TableHeaders<ImportadorPessoa>[] = [
    {
      label: "CNPJ",
      wrapped: false,
      render: (item) => item.cnpj,
    },
    {
      label: "Celular",
      wrapped: false,
      render: (item) => item.celular
    },
    {
      label: "Status",
      wrapped: true,
      render: (item) => item.status,
    },
    {
      label: "Empresa",
      wrapped: true,
      render: (item) => <> <ShowArray arrayName="empresas" value={item.array_empresas * 1} /> </>,
    },
  ];

  const navigate = useNavigate();

  const [fileUploaded, setFileUploaded] = useState<any>(null);
  const [dadosFormatados, setDadosFormatados] = useState<{ cnpj: string; celular: string; empresa: number; observacoes: string }[]>([]);
  const [empresa, setEmpresa] = useState<number>(1);

  useEffect(() => {
    if (fileUploaded) {
      let cnpj = "";
      let celular = "";
      let out_2022 = '';
      let nov_2022 = '';
      let dez_2022 = '';
      let jan_2023 = '';
      let fev_2023 = '';
      let mar_2023 = '';
      let abril_2023 = '';
      let maio_2023 = '';
      let jun_2023 = '';
      let produto = '';
      let descricao = '';

      const dados: { cnpj: string; celular: string; empresa: number; observacoes: string }[] = [];

      Object.keys(fileUploaded[0]).forEach((i) => {
        switch (fileUploaded[0][i]) {
          case 'cnpj':
            cnpj = i;
            break;
          case 'celular':
            celular = i;
            break;
          case 'out_2022':
            out_2022 = i;
            break;
          case 'nov_2022':
            nov_2022 = i;
            break;
          case 'dez_2022':
            dez_2022 = i;
            break;
          case 'jan_2023':
            jan_2023 = i;
            break;
          case 'fev_2023':
            fev_2023 = i;
            break;
          case 'mar_2023':
            mar_2023 = i;
            break;
          case 'abril_2023':
            abril_2023 = i;
            break;
          case 'maio_2023':
            maio_2023 = i;
            break;
          case 'jun_2023':
            jun_2023 = i;
            break;
          case 'produto':
            produto = i;
            break;
          case 'descricao':
            descricao = i;
            break;
          default:
            break;
        }
      })

      fileUploaded.forEach((i: any, index: number) => {
        if (index > 0) {
          let cnpjFormatado = retornaApenasNumero(i[cnpj].toString());

          if (cnpjFormatado.length == 12) {
            cnpjFormatado = "00" + cnpjFormatado;
          }
          if (cnpjFormatado.length == 13) {
            cnpjFormatado = "0" + cnpjFormatado;
          }

          let observacoes = "";
          if (i[produto]) {
            observacoes += `Compra o produto ${i[produto]} - ${i[descricao]} \n`;
          }
          if (i[out_2022]) {
            observacoes += `Comprou ${parseInt(i[out_2022])} em outubro de 2022\n`;
          }
          if (i[nov_2022]) {
            observacoes += `Comprou ${parseInt(i[nov_2022])} em novembro de 2022\n`;
          }
          if (i[dez_2022]) {
            observacoes += `Comprou ${parseInt(i[dez_2022])} em dezembro de 2022\n`;
          }
          if (i[jan_2023]) {
            observacoes += `Comprou ${parseInt(i[jan_2023])} em janeiro de 2023\n`;
          }
          if (i[fev_2023]) {
            observacoes += `Comprou ${parseInt(i[fev_2023])} em fevereiro de 2023\n`;
          }
          if (i[mar_2023]) {
            observacoes += `Comprou ${parseInt(i[mar_2023])} em março de 2023\n`;
          }
          if (i[abril_2023]) {
            observacoes += `Comprou ${parseInt(i[abril_2023])} em abril de 2023\n`;
          }
          if (i[maio_2023]) {
            observacoes = `Comprou ${parseInt(i[maio_2023])} em maio de 2023\n`;
          }
          if (i[jun_2023]) {
            observacoes += `Comprou ${parseInt(i[maio_2023])} em junho de 2023\n`;
          }

          dados.push({
            cnpj: cnpjFormatado,
            celular: retornaApenasNumero(i[celular].toString()),
            observacoes: observacoes,
            empresa: empresa,
          })
        }
      });

      console.log('dados', dados)
      setDadosFormatados(dados);
    }

  }, [fileUploaded, empresa])

  const handleUpload = (e: any) => {
    e.preventDefault();

    var files = e.target.files, f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e?.target?.result;
      let readedData = XLSX.read(data, { type: 'binary' });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      setFileUploaded(dataParse);
    };
    reader.readAsBinaryString(f)
  }

  const onEnviaPraFila = async () => {
    await apiCall({
      url: `envia-fila-importacao-cliente`, method: 'POST', data: { dados: dadosFormatados }
    });
  }

  return (
    <Layout>
      <ListHeader label="Importador De Pessoas">
        <Flex width={"100%"} wrap={"wrap"}>
          <Input width={"50%"} onChange={handleUpload} type="file" />
          <Select width={"50%"} onChange={(e) => setEmpresa(parseInt(e.target.value))}>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
          </Select>
        </Flex>
        <Button colorScheme="green" onClick={onEnviaPraFila} title={JSON.stringify(dadosFormatados[0])}>
          {dadosFormatados.length} itens
        </Button>
      </ListHeader>

      <Loader isLoading={isLoading} />

      <ListInput label="Filtrar por cnpj ou celular" onSubmit={setTermo} >
        <Select
          onChange={(e) => setFiltros({
            ...filtros,
            status: e.target.value,
          })}
          value={filtros.status}
          borderRadius={2}
          bg={"white"}
        >
          <option value="">Todos</option>
          <option value="Na fila">Na fila</option>
          <option value="Erro">Erro</option>
          <option value="Importado">Importado</option>
        </Select>
      </ListInput>

      {importadorPessoas && <ResponsiveTable onClick={(item: any) => navigate(`/importador-pessoas/${item.item.id}`)} headers={headers} data={importadorPessoas.data} />}

      {importadorPessoas && <Pagination info={importadorPessoas} paginate={setPage} />}
    </Layout>
  )
}