import './Layout.css';
import React, { useEffect, ReactNode, useState } from 'react';
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  useColorMode,
  Img,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Button,
  ModalFooter,
  ModalBody,
  ButtonGroup,
} from '@chakra-ui/react';
import {
  FiTrendingUp,
  FiMenu,
  FiUsers,
  FiLogOut,
  FiDownload,
  FiPlus,
  FiSettings,
  FiAlignLeft,
  FiGrid,
  FiActivity,
  FiLayout,
  FiCalendar,
  FiList,
  FiFileText,
  FiBell,
  FiPackage,
  FiShoppingBag,
  FiBarChart2,
} from 'react-icons/fi';
import {
  FaUsersCog,
  FaPeopleCarry,
} from 'react-icons/fa';

import { IconType } from 'react-icons';
import { ReactText } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/mainReducer';
import { usePWAInstall } from 'react-use-pwa-install';
import logoPadrao from "../../../images/logoPadrao.png";
import { APIURL } from '../../utils/apiCall';

enum Parametros {
  usa_cadastro_de_produto = 'usa_cadastro_de_produto'
}
interface LinkItemProps {
  name: string;
  href: string;
  icon: IconType;
  somenteAdmin?: boolean;
  somenteDesenvolvedor?: boolean;
  quantidade?: string;
  exigeParametroAtivo?: Parametros;
}

const LinkItems: Array<LinkItemProps> = [
  { name: 'Início', icon: FiTrendingUp, href: '/' },
  { name: 'Mensalidade', icon: FiBell, href: '/mensalidade', quantidade: 'mensalidade_em_aberto' },
  { name: 'Clientes', icon: FiUsers, href: '/relacao-pessoas/clientes' },
  { name: 'Fornecedores', icon: FaPeopleCarry, href: '/relacao-pessoas/fornecedores' },
  { name: 'Contas a Pagar', icon: FiBarChart2, href: '/financeiro/pagar' },
  { name: 'Contas a Receber', icon: FiBarChart2, href: '/financeiro/receber' },
  { name: 'Fichas CRM', icon: FiLayout, href: '/fichas-crm' },
  { name: 'Agendamentos', icon: FiCalendar, href: '/agendamentos' },
  { name: 'Contatos Realizados', icon: FiList, href: '/relacao-contatos-realizados' },
  { name: 'Pedidos', icon: FiFileText, href: '/relacao-pedidos' },
  { name: 'Produtos', icon: FiPackage, href: '/produtos', exigeParametroAtivo: Parametros.usa_cadastro_de_produto },
  { name: 'Sacolas', icon: FiShoppingBag, href: '/sacolas', exigeParametroAtivo: Parametros.usa_cadastro_de_produto },
  { name: 'Notas Promissórias', icon: FiFileText, href: '/notas-promissorias' },
  { name: 'Cidades', icon: FiGrid, href: '/cidades' },
  // { name: 'Rotas', icon: FiActivity, href: '/rotas' },
  { name: 'Tabelas de Preço', icon: FiAlignLeft, href: '/tabelas' },
  { name: 'Usuários', icon: FaUsersCog, href: '/usuarios', somenteAdmin: true },
  { name: 'Assinaturas', icon: FiBell, href: '/sistema-assinatura', somenteDesenvolvedor: true },
  { name: 'Parâmetros', icon: FiSettings, href: '/parametros', somenteDesenvolvedor: true },
  { name: 'Listas', icon: FiList, href: '/listas', somenteDesenvolvedor: true },
  { name: 'Empresas', icon: FiList, href: '/empresas', somenteDesenvolvedor: true },
];

export default function Layout({ children, id, padding = 4 }: { children: ReactNode, id?: string, padding?: number }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { colorMode, toggleColorMode } = useColorMode()

  useEffect(() => {
    if (colorMode === 'dark') {
      toggleColorMode();
    }
  }, [colorMode, toggleColorMode])


  return (
    <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="xs">
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav display={{ base: 'flex', md: 'none' }} onOpen={onOpen} />
      <Box bg={useColorModeValue('gray.100', 'gray.900')} ml={{ base: 0, md: 60 }} p={padding} id={id}>
        {children}
      </Box>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const InstallButton = () => {
  const install = usePWAInstall()

  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleBeforeInstallPrompt = (event: any) => {
    // Prevent Chrome 76 and later from showing the mini-infobar
    event.preventDefault();
    // Stash the event so it can be triggered later.
    setDeferredPrompt(event);
  };

  function iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  if (iOS()) {
    return <>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"sm"}>
        <ModalOverlay />
        <ModalContent top={-10}>
          <ModalHeader>
            <Flex wrap="wrap" width="full" borderBottom="1px solid #a0a0a0" pb={2}>
              <Text fontSize="1.3rem" color="#404244" fontWeight="700">
                Instale o aplicativo AGSolution
              </Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box width={"full"}>
              <Flex width={"full"} wrap={"wrap"}>
                <ButtonGroup alignItems={"center"}>
                  <Img style={{ width: "30px" }} src='https://upload.wikimedia.org/wikipedia/commons/c/c8/Ei-share-apple.svg' />
                  1) No SAFARI, pressione o botão "Compartilhar"
                </ButtonGroup>
                <ButtonGroup mt={2} alignItems={"center"}>
                  <FiPlus fontSize={"30px"} />
                  2) Clique em "Adicionar à Tela de início"
                </ButtonGroup>
              </Flex>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} ml={2} mt={2} colorScheme={"gray"}>Voltar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <NavItem onClick={onOpen} icon={FiDownload} href='#'>
        Baixar aplicativo
      </NavItem>
    </>
  }

  return (
    <NavItem onClick={install} icon={FiDownload} href='#'>
      Baixar aplicativo
    </NavItem>
  );
};

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const user = useSelector((state: RootState) => state.sistema.user);
  const logoEmpresa = useSelector((state: RootState) => state.sistema.parametros?.logo)
  const parametros = useSelector((state: RootState) => state.sistema.parametros)

  return (
    <Box
      overflowY={"auto"}
      boxShadow="0px 0px 10px 2px rgba(0, 0, 0, 0.2)"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      {...rest}>
      <Flex h={{ base: '100px', md: '150px' }} alignItems="center" mx="8" justifyContent="space-between">
        <Img mx={"auto"} height={{ base: '80px', md: '130px' }} src={logoEmpresa ? APIURL.replace('/api', '/storage/images/') + logoEmpresa : logoPadrao} />
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {LinkItems.filter((i) => {
        if (user?.e_desenvolvedor == '1') return true;
        if (i.somenteDesenvolvedor && user?.e_desenvolvedor == '0') return false;

        if (i.somenteAdmin && user?.e_administrador == '0') return false;

        if (i.exigeParametroAtivo) {
          return (parametros as any) && (parametros as any)[i.exigeParametroAtivo] == 1;
        }
        return true;
      }).map((link) => (
        <NavItem key={link.name} icon={link.icon} href={link.href ? link.href : '#'} quantidade={link.quantidade}>
          {link.name}
        </NavItem>
      ))}
      <InstallButton />
      <NavItem icon={FiLogOut} href={'/logout'}>
        Sair
      </NavItem>
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  href: string;
  children: ReactText;
  quantidade?: string;
}
const NavItem = ({ icon, href, quantidade, children, ...rest }: NavItemProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const cor_primaria = useSelector((state: RootState) => state.sistema?.parametros?.cor_primaria ? state.sistema?.parametros?.cor_primaria : state.sistema.cor_primaria)
  const cor_primaria_hover = useSelector((state: RootState) => state.sistema?.parametros?.cor_primaria_hover ? state.sistema?.parametros?.cor_primaria_hover : state.sistema.cor_primaria_hover)
  const quantidadeMensalidadesEmAberto = useSelector((state: RootState) => state.sistema?.mensalidadesEmAberto)

  return (
    <Link onClick={() => navigate(href)} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
      <Flex
        position={"relative"}
        my={1}
        bg={location.pathname == href ? cor_primaria : ''}
        color={location.pathname == href ? 'white' : ''}
        align="center"
        p="2"
        mx="2"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: cor_primaria_hover,
          color: 'white',
        }}
        {...rest}>
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
        {quantidade === 'mensalidade_em_aberto' && <Box bg={"red.500"} px={2} borderRadius={"full"} color={"white"} position={"absolute"} fontSize={"12px"} top={0} left={"8rem"}>
          <b> {(quantidadeMensalidadesEmAberto ?? []).length} </b>
        </Box>}
      </Flex>
    </Link>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  const logoEmpresa = useSelector((state: RootState) => state.sistema.parametros?.logo)

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent="flex-start"
      {...rest}>
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text fontSize="2xl" ml="8" fontFamily="monospace" fontWeight="bold">
        <Img height={{ base: '60px', md: 'auto' }} src={logoEmpresa ? APIURL.replace('/api', '/storage/images/') + logoEmpresa : logoPadrao} />
      </Text>
    </Flex>
  );
};