import { useIsAuth } from "../../hooks/useIsAuth"
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../sistema/components/Layout";
import { ListHeader } from "../../sistema/components/ListHeader";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import Loader from "../../sistema/components/Loader";
import { crmActions } from "../reducer";
import { FichaCrmCriar } from "../components/FichaCrmCriar";
import { FichaCrmGerenciar, coresStatusCards } from "../components/FichaCrmGerenciar";
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { ConfirmButton } from "../../sistema/components/ConfirmButton";
import { apiCall } from "../../utils/apiCall";
import { mensagemErro, mensagemSucesso } from "../../utils/toasts";
import { formatError } from "../../utils/formatError";
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";
import { FichaPessoa } from "../types/ficha_pessoa";
import { PDFViewer } from "@react-pdf/renderer";
import { FichaCrmPdf } from "../components/FichaCrmPdf";
import { formatData, parseData } from "../../utils/data";

export const FichaCrm = () => {
  useIsAuth();

  const { id } = useParams();

  const isLoading = useSelector((state: RootState) => !!state.crm.isLoading);
  const isLoadingCadastro = useSelector((state: RootState) => !!state.cadastro.isLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(crmActions.buscaFichaSelectInputsRequest())
  }, [dispatch])

  useEffect(() => {
    if (id != 'novo') {
      dispatch(crmActions.fichasRequest())
    }
  }, [dispatch, id])

  const fichas = useSelector((state: RootState) => state.crm.fichas ?? []);
  const filtrosFichaGerenciamento = useSelector((state: RootState) => state.crm.filtrosFichaGerenciamento);

  const onFinalizaFicha = async () => {
    try {
      const ficha = fichas.find((i) => i.id == id);

      dispatch(crmActions.isLoading(true))
      await apiCall({
        url: `fichas`, method: 'POST', data: {
          ...ficha,
          ativo: 0,
          changed: true,
        }
      });
      dispatch(crmActions.fichasRequest())
      mensagemSucesso("Ficha finalizada com sucesso!");
    } catch (err: any) {
      mensagemErro(formatError(err))
      dispatch(crmActions.isLoading(false))
    }
  }

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [itens, setItens] = useState<FichaPessoa[]>([]);
  const [isLoadingLocal, setIsLoadingLocal] = useState<boolean>(false);
  const [isLoadingLocalApagarFicha, setIsLoadingLocalApagarFicha] = useState<boolean>(false);

  const onImprimeFicha = async () => {
    setIsLoadingLocal(true);
    const { data } = await apiCall({
      url: `fichas-page?filtros=${JSON.stringify({
        ...filtrosFichaGerenciamento,
        cidades: filtrosFichaGerenciamento?.cidades.map((i) => i.value),
        rotas: filtrosFichaGerenciamento?.rotas.map((i) => i.value),
        ufs: filtrosFichaGerenciamento?.ufs.map((i) => i.value),
        status: filtrosFichaGerenciamento?.status.map((i) => i.value),
      })}&page=${filtrosFichaGerenciamento?.page}&apenasDados=sim`, method: 'GET'
    });
    setIsLoadingLocal(false);
    setItens(data.itens);
    onOpen();
  }

  const parametros = useSelector((state: RootState) => state.sistema?.parametros)

  const navigate = useNavigate();

  const onApagaFicha = async () => {
    try {
      const fichaId = fichas.find((i) => i.id == id as any)?.id;
      setIsLoadingLocalApagarFicha(true);
      await apiCall({
        url: `fichas/${fichaId}`, method: 'DELETE'
      });
      setIsLoadingLocalApagarFicha(false);
      mensagemSucesso("Ficha apagada com sucesso!");
      navigate('/fichas-crm')
    } catch (err: any) {
      mensagemErro(formatError(err))
      setIsLoadingLocalApagarFicha(false);
    }
  }

  const itensArrayEmpresas = useSelector((state: RootState) => (state.sistema?.listas ?? {})['empresas'] ?? []);

  const getFiltros = () => {
    const retorno: string[] = [];

    if (filtrosFichaGerenciamento?.ficha_id) {
      retorno.push(`Ficha: ${(fichas ?? []).find((i) => i?.id == filtrosFichaGerenciamento?.ficha_id as any)?.nome} - ${formatData((fichas ?? []).find((i) => i?.id == filtrosFichaGerenciamento?.ficha_id as any)?.data ?? '')}`);
    }
    if (filtrosFichaGerenciamento?.nome) {
      retorno.push(`Cliente: ${filtrosFichaGerenciamento.nome}`);
    }
    if (filtrosFichaGerenciamento?.data_integracao) {
      retorno.push(`Data integração: ${parseData(filtrosFichaGerenciamento.data_integracao, 'dd/MM/yyyy')}`);
    }
    if (filtrosFichaGerenciamento?.status?.length) {
      retorno.push(`Status: ${(filtrosFichaGerenciamento.status.map((j) => j.label) as string[]).join(', ')}`);
    }
    if (filtrosFichaGerenciamento?.ufs?.length) {
      retorno.push(`Estados: ${(filtrosFichaGerenciamento.ufs.map((j) => j.label) as string[]).join(', ')}`);
    }
    if (filtrosFichaGerenciamento?.cidades?.length) {
      retorno.push(`Cidade: ${(filtrosFichaGerenciamento.cidades.map((j) => j.label) as string[]).join(', ')}`);
    }
    if (filtrosFichaGerenciamento?.rotas?.length) {
      retorno.push(`Rotas: ${(filtrosFichaGerenciamento.rotas.map((j) => j.label) as string[]).join(', ')}`);
    }
    if (typeof filtrosFichaGerenciamento?.status_item === 'number' && filtrosFichaGerenciamento?.status_item >= 0) {
      retorno.push(`Status Itens: ${coresStatusCards.find((i) => i.value == filtrosFichaGerenciamento?.status_item)?.name}`);
    }
    if (filtrosFichaGerenciamento?.empresas) {
      retorno.push(`Empresa: ${itensArrayEmpresas?.find((i) => i.value == filtrosFichaGerenciamento.empresas)?.label}`);
    }

    if (retorno.length === 0) return ['Nenhum'];

    return retorno;
  }

  return (
    <Layout>
      <ListHeader label={'Ficha CRM'}>
        {id != 'novo' ? <>
          <DefaultButton ml={4} to={`/fichas-crm/novo`}> Gerar nova Ficha CRM </DefaultButton> &nbsp;
          {id && fichas.find((i) => i.id == id as any)?.ativo == '1' && <ConfirmButton confirm={onFinalizaFicha} colorSheme="red" size="md">Finalizar Ficha CRM</ConfirmButton>} &nbsp;
          {id && !!fichas.find((i) => i.id == id as any) && <Button isLoading={isLoadingLocal} onClick={onImprimeFicha} colorScheme="blackAlpha" size="md">Imprimir</Button>} &nbsp;
          {id && !!fichas.find((i) => i.id == id as any) && <ConfirmButton confirm={onApagaFicha} colorSheme="red" size="md">Apagar Ficha</ConfirmButton>} &nbsp;
        </> : <DefaultButton ml={4} to={`/fichas-crm`}> Voltar </DefaultButton>}
      </ListHeader>

      <Loader isLoading={isLoading || isLoadingCadastro || isLoadingLocalApagarFicha} />

      {id == 'novo' ? <FichaCrmCriar /> : <FichaCrmGerenciar />}

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"full"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ListHeaderSecondary label={'Relatório'} />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isOpen && <PDFViewer style={{ width: "100%", height: "80vh" }}>
              <FichaCrmPdf fichaPessoas={itens} parametros={parametros} filtros={getFiltros()} />
            </PDFViewer>}
            <DefaultButton onClick={onClose} mt={2} width={"full"}>
              Fechar
            </DefaultButton>

          </ModalBody>
        </ModalContent>
      </Modal>

    </Layout>
  )
}