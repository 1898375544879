import { User } from "../types/user";

export const novoUser = (): User => {
  return {
    name: "",
    email: "",
    password: "",
    celular: "",
    ativo: '1',
    e_administrador: '0',
    e_desenvolvedor: '0',
    acessos: [],
  }
}
