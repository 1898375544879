import { useIsAuth } from "../../hooks/useIsAuth";
import Layout from "../components/Layout";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import ResponsiveTable, { TableHeaders } from "../components/ResponsiveTable";
import { useState, useEffect } from "react";
import { sistemaActions } from "../recucer";
import { ListInput } from "../components/ListInput";
import { Pagination } from "../components/Pagination";
import Loader from "../components/Loader";
import { ListHeader } from "../components/ListHeader";
import { Button, Flex, Input, Select } from "@chakra-ui/react";
import * as XLSX from "xlsx";
import { Importador } from "../types/importador";
import { FiEye } from "react-icons/fi";
import { mensagemErro } from "../../utils/toasts";
import { formatError } from "../../utils/formatError";
import { apiCall } from "../../utils/apiCall";

export const ImportadorList = () => {
  useIsAuth();

  const importador = useSelector(
    (state: RootState) => state.sistema.importador
  );
  const isLoading = useSelector(
    (state: RootState) => !!state.sistema.isLoading
  );
  const success = useSelector((state: RootState) => !!state.sistema.success);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [termo, setTermo] = useState("");
  const [filtros, setFiltros] = useState({
    status: "",
  });

  useEffect(() => {
    dispatch(
      sistemaActions.importador({
        page,
        termo,
        filtros,
      })
    );
  }, [dispatch, page, termo, filtros]);

  useEffect(() => {
    if (!success) {
      return;
    }
    dispatch(
      sistemaActions.importador({
        page,
        termo,
      })
    );
  }, [dispatch, success, page, termo]);

  const headers: TableHeaders<Importador>[] = [
    {
      label: "Tipo",
      wrapped: true,
      render: (item) => item.tipo,
    },
    {
      label: "Status",
      wrapped: true,
      render: (item) => item.status,
    },
    {
      label: "JSON",
      wrapped: true,
      render: (item) => (
        <>
          <Button colorScheme="blackAlpha" size={"sm"} title={item.json}>
            <FiEye />
          </Button>
        </>
      ),
    },
    {
      label: "Erro",
      wrapped: false,
      render: (item) => item.error,
    },
  ];

  const [fileUploaded, setFileUploaded] = useState<any>(null);
  const [dadosFormatados, setDadosFormatados] = useState<any>([]);

  useEffect(() => {
    if (fileUploaded) {
      const dados: any[] = [];

      const headers: { name: string; index: number }[] = [];

      Object.keys(fileUploaded[0]).forEach((i, index) => {
        headers.push({ name: fileUploaded[0][i], index });
      });

      fileUploaded.forEach((i: any, index: number) => {
        if (index > 0) {
          let dado: any = {};
          headers.forEach(
            (header) =>
              (dado[header.name.toLowerCase()] = i[header.index] ?? "")
          );
          dados.push(dado);
        }
      });

      console.log("dados", dados);
      setDadosFormatados(dados);
    }
  }, [fileUploaded]);

  const handleUpload = (e: any) => {
    e.preventDefault();

    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e?.target?.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      setFileUploaded(dataParse);
    };
    reader.readAsBinaryString(f);
  };

  const [tipo, setTipo] = useState("cliente");
  const [processando, setProcessando] = useState(false);
  const onEnviaPraFila = async () => {
    try {
      setProcessando(true);
      await apiCall({
        url: "envia-fila-importacao",
        method: "POST",
        data: {
          dados: dadosFormatados.map((i: any) => {
            return {
              tipo,
              json: JSON.stringify(i),
              error: "",
              status: "Na fila",
            };
          }),
        },
      });
      setProcessando(false);
      sistemaActions.importador({
        page,
        termo,
        filtros,
      });
    } catch (err: any) {
      mensagemErro(formatError(err));
    }
    setProcessando(false);
  };

  return (
    <Layout>
      <ListHeader label="Importador">
        <Flex width={"100%"} wrap={"wrap"}>
          <Input width={"100%"} onChange={handleUpload} type="file" />
          <Select
            onChange={(e) => setTipo(e.target.value)}
            value={tipo}
            borderRadius={2}
            bg={"white"}
          >
            <option value="cliente">Cliente</option>
            <option value="fornecedor">Fornecedor</option>
            <option value="financeiro_a_pagar">Financeiro a pagar</option>
          </Select>
        </Flex>
        <Button
          isLoading={processando}
          colorScheme="green"
          onClick={onEnviaPraFila}
          title={JSON.stringify(dadosFormatados[0])}
        >
          {dadosFormatados.length} itens
        </Button>
      </ListHeader>

      <Loader isLoading={isLoading} />

      <ListInput label="Filtrar por tipo" onSubmit={setTermo}>
        <Select
          onChange={(e) =>
            setFiltros({
              ...filtros,
              status: e.target.value,
            })
          }
          value={filtros.status}
          borderRadius={2}
          bg={"white"}
        >
          <option value="">Todos</option>
          <option value="Na fila">Na fila</option>
          <option value="Erro">Erro</option>
          <option value="Importado">Importado</option>
        </Select>
      </ListInput>

      {importador && (
        <ResponsiveTable headers={headers} data={importador.data} />
      )}

      {importador && <Pagination info={importador} paginate={setPage} />}
    </Layout>
  );
};
