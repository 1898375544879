import { SacolaItem } from "../types/sacola_item";

export const novaSacolaItem = (): SacolaItem => {
  return {
    preco: "0",
    quantidade: 1,
    quantidade_devolvida: 0,
    sacola_id: "",
    produto_id: "",
  };
};
