import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useIsAuth } from "../../hooks/useIsAuth"
import { useParams } from "react-router-dom";
import { useEffect, useState } from 'react'
import { cadastroActions } from "../reducer";
import Layout from "../../sistema/components/Layout";
import { ListHeader } from "../../sistema/components/ListHeader";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import Loader from "../../sistema/components/Loader";
import InputField from "../../sistema/components/InputField";
import { Form, Formik, useField, useFormikContext } from "formik";
import { Box, Flex } from "@chakra-ui/react";
import validateForm from "../../utils/validateForm";
import InputSelect from "../../sistema/components/InputSelect";
import { apiCall } from "../../utils/apiCall";
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";
import InputCheck from "../../sistema/components/InputCheck";
import { ButtonHistorico } from "../../sistema/components/ButtonHistorico";
import { BobjetoPadrao } from "../../sistema/components/BobjetoPadrao";
import { Cidade } from "../types/cidade";
import { Filhos } from "../../sistema/components/Filhos";
import { novaPessoaContato } from "../data/pessoa_contato";
import { retornaApenasNumero } from "../../utils/formatString";
import InputNumber from "../../sistema/components/InputNumber";
import { Pessoa } from "../types/pessoa";
import { InputSelectArray } from "../../sistema/components/InputSelectArray";
import { CamposPersonalizadosForm } from "../../sistema/components/CamposPersonalizadosForm";

export const PessoaEdit = () => {
  useIsAuth();

  const { id, tipo } = useParams();

  const [tipoCadastro, setTipoCadastro] = useState("Cliente");

  useEffect(() => {
    if (tipo == 'clientes') {
      setTipoCadastro('Cliente')
      return;
    }
    setTipoCadastro('Fornecedor')
  }, [tipo, setTipoCadastro])

  const pessoa = useSelector((state: RootState) => state.cadastro.pessoa || null)
  const isLoading = useSelector((state: RootState) => !!state.cadastro.isLoading)
  const isMobile = useSelector((state: RootState) => !!state.sistema.isMobile)

  const [isLoadingLocal, setIsLoadingLocal] = useState(false);

  const dispatch = useDispatch()

  useEffect(() => {
    if (!id) return;

    dispatch(cadastroActions.pessoaRequest(id))
  }, [dispatch, id])

  interface IReceitaWs {
    nome: string;
    logradouro: string;
    numero: string;
    cep: string;
  }

  const [dadosCnpj, setDadosCnpj] = useState<IReceitaWs | null>(null);

  const consultaCnpj = async (cnpj: string) => {
    cnpj = retornaApenasNumero(cnpj);

    console.log(cnpj)

    if (cnpj.length < 14) return;

    try {
      setIsLoadingLocal(true)
      const { data } = await apiCall({ url: `consulta-cnpj/${cnpj}`, method: 'GET' });

      if (!data?.nome) {
        setIsLoadingLocal(false)
        return;
      }

      setDadosCnpj(data);
    } catch (err) {
      console.log(err)
      setIsLoadingLocal(false)
    }

  }

  const CnpjComponent = () => {
    const formik = useFormikContext();
    const [, , { setValue: setNome }] = useField('nome');
    const [, , { setValue: setEndereco }] = useField('endereco');
    const [, , { setValue: setNumero }] = useField('numero');
    const [, , { setValue: setCep }] = useField('cep');

    useEffect(() => {
      if (!dadosCnpj) {
        return;
      }

      setNome(dadosCnpj.nome)
      setEndereco(dadosCnpj.logradouro)
      setNumero(dadosCnpj.numero)
      setCep(retornaApenasNumero(dadosCnpj.cep))

      consultaCep(dadosCnpj.cep)

      setDadosCnpj(null);
    }, [formik, dadosCnpj]);

    return (
      <></>
    );
  }

  interface DadosCep {
    bairro: string;
    complemento: string;
    ibge: string;
    localidade: string;
    uf: string;
    cidade_id: string;
  }

  const [dadosCep, setDadosCep] = useState<DadosCep | null>(null);

  const consultaCep = async (cep: string) => {
    cep = retornaApenasNumero(cep);

    if (cep.length < 8) {
      setIsLoadingLocal(false);
      return;
    }

    try {
      const { data } = await apiCall({ url: `consulta-cep/${cep}`, method: 'GET' });

      if (!data?.cidade_id) {
        setIsLoadingLocal(false);
        return;
      }

      setIsLoadingLocal(false)

      setDadosCep(data);
    } catch (err: any) {
      setIsLoadingLocal(false)
    }
  }

  const CepComponent = () => {
    const [, , { setValue: setValueCidadeId }] = useField('cidade_id');

    useEffect(() => {
      if (!dadosCep) {
        return;
      }

      setValueCidadeId(dadosCep.cidade_id);

      setDadosCep(null);
    }, [setValueCidadeId, dadosCep]);

    return (
      <></>
    );
  };

  const w1_2 = isMobile ? '100%' : '50%';
  const w1_3 = isMobile ? '100%' : '33%';
  const w1_4 = isMobile ? '100%' : '25%';
  const w1_5 = isMobile ? '100%' : '20%';
  const w1_10 = isMobile ? '100%' : '10%';

  const ePessoaJuridica = (values: Pessoa) => {
    return (values?.tipo_parceiro ?? '').trim() == 'Pessoa jurídica';
  }

  return (
    <Layout>
      <ListHeader label={`${tipoCadastro} ${pessoa?.codigo && pessoa?.codigo > 0 ? pessoa?.codigo : ''}`}>
        <DefaultButton ml={4} to={tipo == 'clientes' ? '/relacao-pessoas/clientes' : '/relacao-pessoas/fornecedores'}> Voltar </DefaultButton>
      </ListHeader>

      <Loader isLoading={isLoading || isLoadingLocal} />

      {pessoa && <Formik
        enableReinitialize
        initialValues={pessoa}
        onSubmit={(val, { setErrors }) => {
          const validation = validateForm({ nome: 'required', tipo_parceiro: 'required' }, val)
          if (Object.keys(validation).length > 0) {
            setErrors(validation)
            return;
          }

          dispatch(cadastroActions.savePessoaRequest({
            ...val,
            e_cliente: tipo == 'clientes' ? 1 : 0,
            e_fornecedor: tipo != 'clientes' ? 1 : 0,
            changed: true,
          }));
        }}
      >
        {({ values }) => (
          <Form>
            <Flex width="full" wrap="wrap">

              <InputSelect label="Status" name="status" width={w1_10}>
                <option value="Ativo">Ativo</option>
                <option value="Inativo">Inativo</option>
              </InputSelect>
              <InputSelect label="Tipo Pessoa" name="tipo_parceiro" width={w1_5}>
                <option value="Pessoa física">Pessoa física</option>
                <option value="Pessoa jurídica">Pessoa jurídica</option>
              </InputSelect>
              <InputField onBlur={() => consultaCnpj(values.cpf_cnpj)} mask={ePessoaJuridica(values) ? '99.999.999/9999-99' : '999.999.999-99'} label={ePessoaJuridica(values) ? 'CNPJ' : 'CPF'} width={w1_5} name="cpf_cnpj" />
              <CnpjComponent />
              <InputField label="Nome" width={w1_2} name="nome" />
              <InputField mask="99.999-999" onBlur={() => consultaCep(values.cep)} label="CEP" name="cep" width={w1_5} />
              <CepComponent />
              <BobjetoPadrao endpoint="cidades" label="Cidade" name="cidade_id" visualizacao={(item: Cidade) => (<>{item?.nome} - {item?.uf}</>)} width={w1_4} />
              <InputField label="Endereço" name="endereco" width={w1_5} />
              <InputField label="Número" name="numero" width={w1_10} />
              <InputField label="Complemento" name="complemento" width={w1_4} />
              <InputField label="E-mail" name="email" width={w1_5} />
              <InputNumber label="Limite de crédito" name="limite_credito" width={w1_5} />
              <InputSelectArray label="Empresa Origem" arrayName="empresas" name="array_empresas" width={w1_5} />
              <InputField type="date" label="Data Integração" name="data_integracao" width={w1_5} />
              {tipo == 'clientes' && <>
                <InputField type="date" name="data_seproque" label="Data do seproque" width={w1_5} />
                <InputCheck name="e_seprocado" label="Seprocado?" mt={6} width={w1_5} />
              </>}
              <CamposPersonalizadosForm par={tipo == 'clientes' ? 'pessoa' : 'pessoa-fornecedor'} idp={pessoa?.id as number} />
              <InputField label="Observações" name="observacoes" width={"100%"} textarea={true} rows={3} />

              {/* {tipo == `none` && <>
                <ListHeaderSecondary label="Dados Complementares" />

                <InputField type="date" name="data_nascimento" label="Data nascimento" width={w1_4} />
                <InputSelect label="Estado Civil" name="estado_civil" width={w1_4}>
                  <option value="Solteiro">Solteiro</option>
                  <option value="Casado">Casado</option>
                </InputSelect>
                <InputField name="nome_conjuge" label="Nome conjuge" width={w1_4} />
                <InputField name="naturalidade" label="Naturalidade" width={w1_4} />
                <InputField name="endereco_comercial" label="Endereço comercial" width={w1_4} />
                <InputField name="referencia_comercial" label="Referência comercial" width={w1_4} />
                <InputField name="nome_pai" label="Nome do pai" width={w1_4} />
                <InputField name="nome_mae" label="Nome da mãe" width={w1_4} />
                <InputField name="parente" label="Parente" width={w1_4} />
              </>} */}

              <Filhos
                name="contatos"
                tituloForm="Contatos"
                editTituloForm="Contato"
                novoItemObject={novaPessoaContato()}
                validation={{ celular: 'required', tipo: 'required' }}
                formatValuesBeforeSave={(values) => ({ ...values, celular: retornaApenasNumero(values.celular) })}
                headers={[
                  {
                    label: "Tipo",
                    wrapped: false,
                    render: (item) => item.tipo,
                  },
                  {
                    label: "Nome",
                    wrapped: false,
                    render: (item) => item.nome,
                  },
                  {
                    label: "Celular",
                    wrapped: true,
                    render: (item) => item.celular,
                  },
                  {
                    label: "Observações",
                    wrapped: false,
                    render: (item) => item.observacoes,
                  }
                ]}
                form={(values) =>
                  <Flex width={"full"} wrap={"wrap"}>
                    <InputSelect width={w1_3} name="tipo" label="Tipo de Contato">
                      <option value="Principal">Principal</option>
                      <option value="Comprador">Comprador</option>
                      <option value="Financeiro">Financeiro</option>
                      <option value="Vendedor">Vendedor</option>
                    </InputSelect>
                    <InputField name="nome" label="Nome da Pessoa" width={w1_3} />
                    <InputField mask="(99)99999-9999" label="Celular" name="celular" width={w1_3} />
                    <InputField textarea label="Observações" name="observacoes" rows={3} width={'100%'} />
                  </Flex>
                }
              />
              <Flex width={"full"} />
              <Box
                bg={"white"}
                mt={2}
                width={"full"}
                position="sticky"
                bottom="10px"
                zIndex="999"
                p={2}
              >
                <DefaultButton type="submit">Salvar cadastro</DefaultButton>
                {values.id && <ButtonHistorico par="pessoa" idpar={values.id} ml={2} />}
              </Box>
            </Flex>
          </Form>
        )}
      </Formik>}
    </Layout>
  )
}