import { Box, ButtonGroup, Divider, Flex, FormControl, FormLabel, Input, Td } from "@chakra-ui/react"
import { Form, Formik } from "formik"
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import InputField from "../../sistema/components/InputField";
import { RootState } from "../../app/mainReducer";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../sistema/components/Layout";
import { useIsAuth } from "../../hooks/useIsAuth";
import { ListHeader } from "../../sistema/components/ListHeader";
import { Pagination } from "../../sistema/components/Pagination";
import { formatValor } from "../../utils/formatValor";
import Loader from "../../sistema/components/Loader";
import { formatData, getCurrentData, getDataMaisDias, getDataMenosDias, parseData } from "../../utils/data";
import { CamposPersonalizadosFiltros } from "../../sistema/components/CamposPersonalizadosFiltros";
import { mensagemErro } from "../../utils/toasts";
import { PessoaBobjeto } from "../../cadastros/components/PessoaBobjeto";
import { financeiroActions } from "../reducer";
import InputSelect from "../../sistema/components/InputSelect";
import ResponsiveTable, { TableHeaders } from "../../sistema/components/ResponsiveTable";
import { FinanceiroParcela } from "../types/financeiro_parcela";
import { formatCpfCnpj } from "../../utils/formatCpfCnpj";
import { FiEye } from "react-icons/fi";
import { CamposPersonalizadosInfoButton } from "../../sistema/components/CamposPersonalizadosInfoButton";
import { formatNumber } from "../../utils/formatNumber";
import InputNumber from "../../sistema/components/InputNumber";
import { formatError } from "../../utils/formatError";
import { apiCall } from "../../utils/apiCall";
import InputCheck from "../../sistema/components/InputCheck";

export type IFiltrosRelatorioFinanceiro = {
  pessoa_id: string;
  codigo: string;
  data_vencimento_ini: string;
  data_vencimento_fim: string;
  data_emissao_ini: string;
  data_emissao_fim: string;
  status: number;
  page: number;
  tipo: string;
}

export const FinanceiroList = () => {
  useIsAuth();

  // tipo = pagar, receber
  const { tipo } = useParams();

  const [tipoFinanceiro, setTipoFinanceiro] = useState("A Pagar");

  const navigate = useNavigate();

  useEffect(() => {
    if (tipo == 'receber') {
      setTipoFinanceiro('A Receber')
      return;
    }
    setTipoFinanceiro('A Pagar')

    if (tipo != 'receber' && tipo != 'pagar') {
      mensagemErro("Não autorizado.");
      navigate(-1);

    }
  }, [tipo, setTipoFinanceiro])

  const [page, setPage] = useState(1);

  const [currentItem, setCurrentItem] = useState<IFiltrosRelatorioFinanceiro>({
    pessoa_id: '',
    codigo: '',
    data_vencimento_ini: getDataMenosDias(15),
    data_vencimento_fim: getDataMaisDias(15),
    data_emissao_ini: '',
    data_emissao_fim: '',
    status: 3,
    page: 1,
    tipo: tipo as any,
  })

  useEffect(() => {
    setCurrentItem({
      ...currentItem,
      page,
    })
    dispatch(financeiroActions.buscaRelatorioFinanceiroRequest({
      ...currentItem,
      page,
    }));
  }, [page])

  useEffect(() => {
    setCurrentItem({
      ...currentItem,
      page,
      tipo: tipo as any,
    })
    dispatch(financeiroActions.buscaRelatorioFinanceiroRequest({
      ...currentItem,
      page,
      tipo: tipo as any,
    }));
  }, [tipo])

  const dispatch = useDispatch();

  const isMobile = useSelector((state: RootState) => state.sistema.isMobile);
  const isLoading = useSelector((state: RootState) => !!state.financeiro.isLoading);
  const relatorioFinanceiro = useSelector((state: RootState) => state.financeiro.relatorioFinanceiro);

  const getLarguraPagina = (partes: number) => {
    if (isMobile) return '100%';

    return `${100 / partes}%`;
  }

  const tableHeaders: TableHeaders<FinanceiroParcela>[] = [
    {
      label: "Número/Parcela",
      wrapped: true,
      render: (item) => <>{item.financeiro?.codigo}/{item?.codigo} <Link target="_blank" to={`/financeiro/${tipo}/${item.financeiro_id}`}> <DefaultButton title="Ver financeiro" size={"xs"}><FiEye /> &nbsp; Ver</DefaultButton></Link>&nbsp;<CamposPersonalizadosInfoButton size="xs" camposPersonalizados={item?.financeiro?.camposPersonalizados ?? []} /></>,
    },
    {
      label: "Pessoa",
      wrapped: false,
      render: (item) => `${item.financeiro?.pessoa?.nome} (${formatCpfCnpj(item.financeiro?.pessoa?.cpf_cnpj ?? '')})`,
    },
    {
      label: "Emissão",
      wrapped: false,
      render: (item) => formatData(item?.financeiro?.data_emissao ?? '', 'dd/MM/yyyy'),
    },
    {
      label: "Vencimento",
      wrapped: false,
      render: (item) => parseData(item.data_vencimento, 'dd/MM/yyyy'),
    },
    {
      label: "Valor",
      wrapped: false,
      render: (item) => formatValor(item.valor),
    },
    {
      label: "Valor Pago",
      wrapped: false,
      render: (item) => formatValor(item.valor_quitado),
    },
    {
      label: "Status",
      wrapped: true,
      render: (item) => item.status == 1 ? 'Parcialmente Quitado' : (item.status == 2 ? 'Quitado' : 'Em aberto'),
    },
  ];

  const [selectedItems, setSelectedItems] = useState<FinanceiroParcela[]>([]);

  const getValoresBaixa = () => {
    let valores: any = [];

    for (const parcela of (relatorioFinanceiro?.data ?? [])) {
      (valores as any)[parcela.id as number] = parcela._valor_baixa;
    }

    return valores;
  }

  const getAtualizaValores = () => {
    let valores: any = [];

    for (const parcela of (relatorioFinanceiro?.data ?? [])) {
      (valores as any)[parcela.id as number] = 0;
    }

    return valores;
  }

  const [isLoadingLocal, setIsLoadingLocal] = useState(false);

  return (
    <Layout>
      <Box width={"full"}>
        <ListHeader label={`Contas ${tipoFinanceiro}`}>
          <DefaultButton ml={4} to={`/financeiro/${tipo}/novo`}> Nova conta {tipoFinanceiro} </DefaultButton>
        </ListHeader>
        <Loader isLoading={isLoading} />
        {currentItem && <Formik
          enableReinitialize
          initialValues={currentItem}
          onSubmit={async (val, { setErrors }) => {

            setCurrentItem(val);

            setPage(1)

            dispatch(financeiroActions.buscaRelatorioFinanceiroRequest({
              ...val,
              page,
            }));
          }}
        >
          {({ values }) => (
            <Form>
              <Flex width="full" wrap="wrap">
                <PessoaBobjeto label="Pessoa" name="pessoa_id" width={getLarguraPagina(5)} />
                <InputField type="date" label="Emissão Início" name="data_emissao_ini" width={getLarguraPagina(5)} />
                <InputField type="date" label="Emissão Fim" name="data_emissao_fim" width={getLarguraPagina(5)} />
                <InputField type="date" label="Vencimento Início" name="data_vencimento_ini" width={getLarguraPagina(5)} />
                <InputField type="date" label="Vencimento Fim" name="data_vencimento_fim" width={getLarguraPagina(5)} />
                <InputSelect label="Status" name="status" width={getLarguraPagina(5)}>
                  <option value="0">Em aberto</option>
                  <option value="1">Parcialmente quitados</option>
                  <option value="2">Quitados</option>
                  <option value="3">Todos</option>
                </InputSelect>
                <InputField label="Código" name="codigo" width={getLarguraPagina(5)} />

                <ButtonGroup alignItems={"center"} mt={2} ml={1}>
                  <CamposPersonalizadosFiltros par="financeiro" />
                  <DefaultButton type="submit">Buscar Financeiros</DefaultButton>
                  &nbsp;  Total de {relatorioFinanceiro?.total ?? 0} parcelas
                </ButtonGroup>
              </Flex>
            </Form>)}
        </Formik>}
      </Box>

      <Flex gap={2} width={"full"} wrap={"wrap"}>
        <Flex bg={"white"} rounded={"15px"} boxShadow={"base"} fontSize={"18px"} direction={"column"} p={4} alignItems={"center"} textAlign={"center"}>
          <b style={{ marginBottom: '10px' }}> VALOR TOTAL </b>
          R$ {formatValor(
            (relatorioFinanceiro?.data ?? []).reduce((acumulador, atual: FinanceiroParcela) => {
              return acumulador += parseFloat(atual.valor);
            }, 0)
            , 2)}
        </Flex>
        <Flex bg={"white"} rounded={"15px"} boxShadow={"base"} fontSize={"18px"} direction={"column"} p={4} alignItems={"center"} textAlign={"center"}>
          <b style={{ marginBottom: '10px' }}> TOTAL EM ABERTO </b>
          R$ {formatValor(
            (relatorioFinanceiro?.data ?? []).reduce((acumulador, atual: FinanceiroParcela) => {
              return acumulador += parseFloat(atual.valor) - parseFloat(atual.valor_quitado);
            }, 0)
            , 2)}
        </Flex>
        <Flex bg={"white"} rounded={"15px"} boxShadow={"base"} fontSize={"18px"} direction={"column"} p={4} alignItems={"center"} textAlign={"center"}>
          <b style={{ marginBottom: '10px' }}> TOTAL QUITADO </b>
          R$ {formatValor(
            (relatorioFinanceiro?.data ?? []).reduce((acumulador, atual: FinanceiroParcela) => {
              return acumulador += parseFloat(atual.valor_quitado);
            }, 0)
            , 2)}
        </Flex>

      </Flex>


      <Formik
        enableReinitialize
        initialValues={{
          data_baixa: getCurrentData('yyyy-MM-dd'),
          _valor_baixa: getValoresBaixa() as any,
          _atualiza_valor: getAtualizaValores() as any,
        }}
        onSubmit={async (val, { setErrors }) => {
          console.log(val)

          const parcelasAQuitar = selectedItems.map((item) => ({
            ...item,
            _valor_baixa: val._valor_baixa[item.id as number],
            _atualiza_valor: val._atualiza_valor[item.id as number],
          })).filter((i) => i._valor_baixa > 0);

          console.log('parcelas a quitar', parcelasAQuitar);

          try {
            setIsLoadingLocal(true);

            await apiCall({ url: `baixa-parcelas`, data: { parcelas: parcelasAQuitar, data_baixa: val.data_baixa }, method: 'POST' });

            setIsLoadingLocal(false);
            setSelectedItems([]);

            dispatch(financeiroActions.buscaRelatorioFinanceiroRequest(currentItem));
          } catch (err: any) {
            setIsLoadingLocal(false);
            mensagemErro(formatError(err));
          }

        }}
      >
        {({ values }) => (
          <Form>

            <ResponsiveTable onSelectForm={((item: FinanceiroParcela) => (
              <>
                <Td textAlign={"right"} colSpan={4}>
                  <small><b> DETALHES DA BAIXA</b> </small>
                </Td>
                <Td textAlign={"right"} colSpan={4}>
                  <InputNumber label="Valor da Baixa" name={`_valor_baixa.[${item.id}]`} />
                  {item.status == 0 && values._valor_baixa[item.id as number] != item._valor_baixa && <InputCheck label="Atualiza o valor total da parcela pelo o valor digitado acima?" name={`_atualiza_valor.[${item.id}]`} />}
                </Td>
              </>
            ))} canSelect={(item: FinanceiroParcela) => item.status != 2} selectedItems={selectedItems} changeItems={setSelectedItems} headers={tableHeaders} data={relatorioFinanceiro?.data ?? []} />

            {relatorioFinanceiro && <Pagination info={relatorioFinanceiro} paginate={setPage} />}

            {selectedItems.length > 0 && <Box
              bg={"white"}
              mt={2}
              width={"full"}
              position="sticky"
              bottom="10px"
              zIndex="999"
              p={2}
            >
              <Flex width="full" wrap="wrap">
                &bull; Total de {selectedItems.length} parcelas (R$ {formatValor(selectedItems.reduce((acumulador, atual: FinanceiroParcela) => {
                  return acumulador += parseFloat((atual.valor ?? '0'));
                }, 0))}) <br />
                &bull; Total de R$ {formatValor(selectedItems.reduce((acumulador, atual: FinanceiroParcela) => {
                  return acumulador += parseFloat(values._valor_baixa[atual.id as number] ?? '0');
                }, 0))} a baixar <br />

                <InputField type="date" label="Data da baixa" name="data_baixa" width={"full"} />

                <Divider my={2} />

                <DefaultButton isLoading={isLoadingLocal} type="submit">Quitar selecionados</DefaultButton>

              </Flex>
            </Box>}
          </Form>)}
      </Formik>



    </Layout >
  )

}