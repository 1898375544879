import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useIsAuth } from "../../hooks/useIsAuth"
import { useParams } from "react-router-dom";
import { useEffect } from 'react'
import { cadastroActions } from "../reducer";
import Layout from "../../sistema/components/Layout";
import { ListHeader } from "../../sistema/components/ListHeader";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import Loader from "../../sistema/components/Loader";
import InputField from "../../sistema/components/InputField";
import { Form, Formik } from "formik";
import { Box, Flex } from "@chakra-ui/react";
import validateForm from "../../utils/validateForm";
import InputSelect from "../../sistema/components/InputSelect";
import { ButtonHistorico } from "../../sistema/components/ButtonHistorico";
import { getArrayUfs } from "../../utils/ufs";

export const CidadeEdit = () => {
  useIsAuth();

  const { id } = useParams();

  const cidade = useSelector((state: RootState) => state.cadastro.cidade)
  const isLoading = useSelector((state: RootState) => !!state.cadastro.isLoading)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!id) return;

    dispatch(cadastroActions.cidadeRequest(id))
  }, [dispatch, id])

  return (
    <Layout>
      <ListHeader label={'Cidade'}>
        <DefaultButton ml={4} to={`/cidades`}> Voltar </DefaultButton>
      </ListHeader>

      <Loader isLoading={isLoading} />

      {cidade && <Formik
        enableReinitialize
        initialValues={cidade}
        onSubmit={(val, { setErrors }) => {
          const validation = validateForm({ nome: 'required', uf: 'required' }, val)
          if (Object.keys(validation).length > 0) {
            setErrors(validation)
            return;
          }

          dispatch(cadastroActions.saveCidadeRequest({
            ...val,
            changed: true,
          }));
        }}
      >
        {({ values }) => (
          <Form>
            <Flex width="full" wrap="wrap">
              <InputField label="Nome" name="nome" width={`50%`} />
              <InputSelect label="UF" name="uf" width={`50%`}>
                {getArrayUfs().map((i) => (
                  <option value={i.value}>{i.name}</option>
                ))}
              </InputSelect>

              <Flex width={"full"} />
              <Box
                bg={"white"}
                mt={2}
                width={"full"}
                position="sticky"
                bottom="10px"
                zIndex="999"
                p={2}
              >
                <DefaultButton type="submit">Salvar cidade</DefaultButton>
                {values.id && <ButtonHistorico par="cidade" idpar={values.id} ml={2} />}
              </Box>
            </Flex>
          </Form>
        )}
      </Formik>}
    </Layout>
  )
}