import { PedidoItem } from "../types/pedido_item";

export const novoPedidoItem = (): PedidoItem => {
  return {
    quantidade: "0",
    valor_unitario: "0",
    valor_total: "0",
    produto: "",
    pedido_id: "",
    peso: "0",
    produto_id: "",
  };
};
