import { getCurrentData } from "../../utils/data";
import { Pessoa } from "../types/pessoa";

export const novaPessoa = (): Pessoa => {
  return {
    codigo: 0,
    e_cliente: 0,
    e_fornecedor: 0,
    e_seprocado: 0,
    data_seproque: "",
    nome: "",
    endereco: "",
    numero: "",
    bairro: "",
    complemento: "",
    cep: "",
    cpf_cnpj: "",
    rg_ie: "",
    tipo_parceiro: "Pessoa jurídica",
    naturalidade: "",
    data_nascimento: "",
    estado_civil: "",
    nome_conjuge: "",
    endereco_comercial: "",
    nome_pai: "",
    nome_mae: "",
    parente: "",
    referencia_comercial: "",
    observacoes: "",
    nome_contato: "",
    transportadora: "",
    email: "",
    status: "Ativo",
    cidade_id: "",
    contatos: [],
    limite_credito: 0,
    array_empresas: 1,
    data_integracao: getCurrentData('yyyy-MM-dd'),
  };
};
