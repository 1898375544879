import { Form, Formik } from "formik";
import { User } from "../types/user"
import { Button, Flex } from "@chakra-ui/react";
import validateForm from "../../utils/validateForm";
import InputField from "./InputField";
import { DefaultButton } from "./DefaultButton";
import InputCheck from "./InputCheck";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { sistemaActions } from "../recucer";
import InputMultiSelect from "./InpurMultiSelect";
import { ButtonHistorico } from "./ButtonHistorico";
import { apiCall } from "../../utils/apiCall";
import { Empresa } from "../types/empresa";
import { useState, useEffect } from 'react'
import InputSelect from "./InputSelect";


interface IProps {
  currentItem: User | null;
  onClose?: () => void;
}

export const UserForm = ({ currentItem, onClose }: IProps) => {
  const e_desenvolvedor = useSelector((state: RootState) => state.sistema.user?.e_desenvolvedor == '1')
  const isMobile = useSelector((state: RootState) => state.sistema.isMobile)

  const [empresas, setEmpresas] = useState<Empresa[]>([]);

  const dispatch = useDispatch()

  useEffect(() => {
    const getData = async () => {
      const { data } = await apiCall({
        url: `todas-empresas`, method: 'GET'
      });
      setEmpresas(data);
    }
    getData();
  }, [dispatch])

  const tiposAcessos = [
    {
      label: 'Controlar Clientes',
      value: 'Controlar Clientes',
    },
    {
      label: 'Controlar Fornecedores',
      value: 'Controlar Fornecedores',
    },
    {
      label: 'Controlar Financeiro',
      value: 'Controlar Financeiro',
    },
  ]

  return (
    <>
      {currentItem && <Formik
        enableReinitialize
        initialValues={currentItem}
        onSubmit={(val, { setErrors }) => {
          const validation = validateForm({ name: 'required' }, val)
          if (Object.keys(validation).length > 0) {
            setErrors(validation)
            return;
          }

          dispatch(sistemaActions.saveUsuarioRequest({
            ...val,
            changed: true,
          }));
        }}
      >
        {({ values }) => (
          <Form>
            <Flex width="full" wrap="wrap">
              <InputField label="Nome" name="name" width={"50%"} />
              <InputField label="Celular" name="celular" type="tel" width={"50%"} />
              <InputField label="E-mail" autoComplete={"off"} name="email" type="email" width={"50%"} />
              <InputField label="Senha" name="password" type="password" width={"50%"} />
              <InputMultiSelect label="Acessos ao sistema" name="acessos" options={tiposAcessos} width={"50%"} />
              <InputSelect label="Empresa" name="empresa_id" width={"50%"}>
                {empresas.map((i) => (
                  <option value={i.id} key={`${i.id}`}>{i.nome} ({i.cpf_cnpj})</option>
                ))}
              </InputSelect>
              <hr style={{ width: '100%' }} />
              <InputCheck width={isMobile ? "100%" : "20%"} label="Administrador do sistema?" name="e_administrador" />
              {e_desenvolvedor && <InputCheck width={isMobile ? "100%" : "20%"} label="Desenvolvedor?" name="e_desenvolvedor" />}

              <InputCheck width={isMobile ? "100%" : "20%"} label="Ativo?" name="ativo" />
              <hr style={{ width: '100%' }} />
              <DefaultButton type="submit" mt={4}>Salvar</DefaultButton>
              {values.id && <ButtonHistorico par="users" idpar={values.id} mt={4} ml={2} />}
              {typeof onClose === 'function' && <Button onClick={onClose} ml={2} mt={4} colorScheme="blackAlpha">Voltar</Button>}
            </Flex>
          </Form>)}
      </Formik>}
    </>
  )
}