import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { TLoginRes } from "./saga";
import { User } from "./types/user";
import { ApiPaginationRes } from "./types/apiPaginationRes";
import { ListPayload } from "./types/listPayload";
import { mensagemErro, mensagemSucesso } from "../utils/toasts";
import { Parametros } from "./types/parametros";
import { Historico } from "./types/historico";
import { ImportadorPessoa } from "./types/importador_pessoa";
import { SistemaAssinatura } from "./types/sistema_assinatura";
import { Empresa } from "./types/empresa";
import { Importador } from "./types/importador";

export type ILista = {
  label: string;
  value: number;
}[];

type TInitialState = {
  user?: User | null;
  token?: string;
  isLoggingIn?: boolean;
  error?: string;
  success?: string;
  isLoading?: boolean;
  usuarios?: ApiPaginationRes<User[]>;
  usuario?: User | null;
  sistemaAssinaturas?: ApiPaginationRes<SistemaAssinatura[]>;
  sistemaAssinatura?: SistemaAssinatura | null;
  empresas?: ApiPaginationRes<Empresa[]>;
  empresa?: Empresa | null;
  isMobile?: boolean;
  cor_primaria: string;
  cor_primaria_hover: string;
  parametros?: Parametros | null;
  historico?: Historico[];
  listas?: {
    [item: string]: ILista;
  };
  importadorPessoas?: ApiPaginationRes<ImportadorPessoa[]>;
  mensalidadesEmAberto?: SistemaAssinatura[];
  importador?: ApiPaginationRes<Importador[]>;
};

const initialState: TInitialState = {
  cor_primaria: "#0c8dc5",
  cor_primaria_hover: "#0b79a8",
};

const reducer = createSlice({
  name: "sistema",
  initialState,
  reducers: {
    loginRequest(state, _: PayloadAction<{ email: string; password: string }>) {
      state.isLoggingIn = true;
      state.error = "";
    },
    loginSuccess(state, { payload }: PayloadAction<TLoginRes>) {
      state.isLoggingIn = false;
      state.user = payload.data.user;
      state.token = payload.data.token;
      state.parametros = payload.data.parametros;
    },
    loginError(state, { payload }: PayloadAction<string>) {
      state.isLoggingIn = false;
      state.error = payload;
    },
    logout(state) {
      state.user = null;
      state.token = "";
      state.error = "";
    },
    error(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = payload;
      state.success = "";
      mensagemErro(payload);
    },
    success(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = "";
      state.success = payload;
      mensagemSucesso(payload);
    },
    getListaRequest(state, _: PayloadAction<string>) {},
    getListaRequestSuccess(
      state,
      { payload }: PayloadAction<{ nome: string; itens: ILista }>
    ) {
      if (!state.listas) state.listas = {};
      state.listas[payload.nome] = payload.itens;
    },
    usuariosRequest(state, _: PayloadAction<ListPayload>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    usuariosRequestSuccess(
      state,
      { payload }: PayloadAction<ApiPaginationRes<User[]>>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.usuarios = payload;
    },
    usuarioRequest(state, _: PayloadAction<string>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
      state.usuario = null;
    },
    usuarioRequestSuccess(state, { payload }: PayloadAction<User>) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.usuario = payload;
    },
    saveUsuarioRequest(state, _: PayloadAction<User>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    saveUsuarioRequestSuccess(state, { payload }: PayloadAction<User>) {
      state.isLoading = false;
      state.error = "";
      state.success = "Usuário salvo com sucesso.";
      state.usuario = payload;
      mensagemSucesso("Usuário salvo com sucesso.");
    },
    deleteUsuariosRequest(state, { payload }: PayloadAction<string | number>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    deleteUsuariosRequestSuccess(state) {
      state.isLoading = false;
      state.error = "";
      state.success = "Usuário apagado com sucesso.";
      mensagemSucesso("Usuário apagado com sucesso.");
    },
    setaIsMobile(state, { payload }: PayloadAction<boolean>) {
      state.isMobile = payload;
    },
    parametrosRequest(state) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    parametrosRequestSuccess(state, { payload }: PayloadAction<Parametros>) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.parametros = payload;
    },
    saveParametrosRequest(state, _: PayloadAction<Parametros>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    saveParametrosRequestSuccess(
      state,
      { payload }: PayloadAction<Parametros>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "Parâmetros salvo com sucesso.";
      state.parametros = payload;
      mensagemSucesso("Parâmetros salvo com sucesso.");
    },
    historicoRequest(state, _: PayloadAction<{ par: string; idpar: string }>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    historicoRequestSuccess(state, { payload }: PayloadAction<Historico[]>) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.historico = payload;
    },
    importadorPessoas(state, _: PayloadAction<ListPayload>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    importadorPessoasSuccess(
      state,
      { payload }: PayloadAction<ApiPaginationRes<ImportadorPessoa[]>>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.importadorPessoas = payload;
    },

    importador(state, _: PayloadAction<ListPayload>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    importadorSuccess(
      state,
      { payload }: PayloadAction<ApiPaginationRes<Importador[]>>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.importador = payload;
    },

    sistemaAssinaturasRequest(state, _: PayloadAction<ListPayload>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    sistemaAssinaturasRequestSuccess(
      state,
      { payload }: PayloadAction<ApiPaginationRes<SistemaAssinatura[]>>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.sistemaAssinaturas = payload;
    },
    sistemaAssinaturaRequest(state, _: PayloadAction<string>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
      state.sistemaAssinatura = null;
    },
    sistemaAssinaturaRequestSuccess(state, { payload }: PayloadAction<SistemaAssinatura>) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.sistemaAssinatura = payload;
    },
    saveSistemaAssinaturaRequest(state, _: PayloadAction<SistemaAssinatura>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    saveSistemaAssinaturaRequestSuccess(state, { payload }: PayloadAction<SistemaAssinatura>) {
      state.isLoading = false;
      state.error = "";
      state.success = "Usuário salvo com sucesso.";
      state.sistemaAssinatura = payload;
      mensagemSucesso("Usuário salvo com sucesso.");
    },
    deleteSistemaAssinaturaRequest(state, { payload }: PayloadAction<string | number>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    deleteSistemaAssinaturaRequestSuccess(state) {
      state.isLoading = false;
      state.error = "";
      state.success = "Sistema Assinatura apagada com sucesso.";
      mensagemSucesso("Sistema Assinatura apagada com sucesso.");
    },

    empresasRequest(state, _: PayloadAction<ListPayload>) {
        state.isLoading = true;
        state.error = "";
        state.success = "";
      },
    empresasRequestSuccess(
      state,
      { payload }: PayloadAction<ApiPaginationRes<Empresa[]>>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.empresas = payload;
    },
    empresaRequest(state, _: PayloadAction<string>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
      state.empresa = null;
    },
    empresaRequestSuccess(state, { payload }: PayloadAction<Empresa>) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.empresa = payload;
    },
    saveEmpresaRequest(state, _: PayloadAction<Empresa>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    saveEmpresaRequestSuccess(state, { payload }: PayloadAction<Empresa>) {
      state.isLoading = false;
      state.error = "";
      state.success = "Usuário salvo com sucesso.";
      state.empresa = payload;
      mensagemSucesso("Usuário salvo com sucesso.");
    },
    deleteEmpresaRequest(state, { payload }: PayloadAction<string | number>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    deleteEmpresaRequestSuccess(state) {
      state.isLoading = false;
      state.error = "";
      state.success = "Sistema Assinatura apagada com sucesso.";
      mensagemSucesso("Sistema Assinatura apagada com sucesso.");
    },

    mensalidadesEmAbertoRequest(state) {
    },
    mensalidadesEmAbertoRequestSuccess(state, { payload }: PayloadAction<SistemaAssinatura[]>) {
      state.mensalidadesEmAberto = payload;
    },
  },
});

export const sistemaActions = reducer.actions;

export default reducer;
