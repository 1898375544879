import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/mainReducer";
import { crmActions } from "../crm/reducer";
import logoPadrao from "../../images/logoPadrao.png";
import { Agendamento } from "../crm/types/agendamento";
import { formatData } from "../utils/data";

export function useNotificaAgendamentos() {
  const dispatch = useDispatch();

  const notificaUsuario = async (titulo: string, agendamento: Agendamento) => {
    dispatch(crmActions.setAgendamentoNotificado(agendamento.id as number));

    if (!("Notification" in window)) {
      console.log("Browser does not support desktop notification");
    } else {
      const retorno = await Notification.requestPermission();

      if (retorno == "granted") {
        const notification = new Notification(titulo, {
          icon: logoPadrao,
          body: `Agendamento marcado para ${
            agendamento?.pessoa?.nome
          } em ${formatData(agendamento.data)} - Descrição: ${agendamento.descricao}`,
        });

        notification.onclick = function () {
          document.location.href =
            window.location.origin.toString() +
            `/agendamentos/${agendamento.id}`;
        };
      }
    }
  };

  const isLogged = useSelector((state: RootState) => !!state.sistema.token);

  const agendamentosNotificados = useSelector(
    (state: RootState) => state.crm?.agendamentosNotificados
  );
  const agendamentosANotificar = (
    useSelector((state: RootState) => state.crm?.agendamentosANotificar) ?? []
  ).filter(
    (i) =>
      !(agendamentosNotificados ?? []).some((j: any) => j == (i.id as number))
  );

  useEffect(() => {
    if (isLogged) {
      const seuProjetoGeral = () => {
        console.log("buscando agendamentos");
        dispatch(crmActions.agendamentosANotificarRequest());
      };

      seuProjetoGeral();

      const intervalId = setInterval(seuProjetoGeral, 60000 * 2);

      return () => clearInterval(intervalId);
    }
  }, [dispatch, isLogged]);

  useEffect(() => {
    if (isLogged) {
      agendamentosANotificar.forEach((agendamento, index) => {
        setTimeout(() => {
          notificaUsuario("Aviso! Agenda próxima.", agendamento);
          console.log("notificando agendamento", agendamento);
        }, (index + 1) * 4000);
      });
    }
  }, [agendamentosANotificar, isLogged]);
}
