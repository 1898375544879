import { useIsAuth } from "../../hooks/useIsAuth"
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { FiTrash } from "react-icons/fi";
import { entradaesaidaActions } from "../recucer";
import ResponsiveTable, { TableHeaders } from "../../sistema/components/ResponsiveTable";
import { formatNumber } from "../../utils/formatNumber";
import { ConfirmButton } from "../../sistema/components/ConfirmButton";
import Layout from "../../sistema/components/Layout";
import { ListHeader } from "../../sistema/components/ListHeader";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import Loader from "../../sistema/components/Loader";
import { ListInput } from "../../sistema/components/ListInput";
import { Pagination } from "../../sistema/components/Pagination";
import { CamposPersonalizadosInfoButton } from "../../sistema/components/CamposPersonalizadosInfoButton";
import { ButtonGroup } from "@chakra-ui/react";
import { Sacola } from "../types/sacola";
import { formatData } from "../../utils/data";
import { ShowArray } from "../../sistema/components/ShowArray";
import { InputSelectArray } from "../../sistema/components/InputSelectArray";
import { Form, Formik } from "formik";

export const SacolasList = () => {
  useIsAuth();

  const sacolas = useSelector((state: RootState) => state.entradaesaida.sacolas);
  const isLoading = useSelector((state: RootState) => !!state.entradaesaida.isLoading);
  const success = useSelector((state: RootState) => !!state.entradaesaida.success);
  const dispatch = useDispatch()
  const [page, setPage] = useState(1);
  const [termo, setTermo] = useState("");
  const [filtros, setFiltros] = useState({
    status: '',
  })

  useEffect(() => {
    dispatch(entradaesaidaActions.sacolasRequest({
      page,
      termo,
      filtros,
    }))
  }, [dispatch, page, termo, filtros])

  useEffect(() => {
    if (!success) {
      return;
    }
    dispatch(entradaesaidaActions.sacolasRequest({
      page,
      termo,
      filtros,
    }))
  }, [dispatch, success, page, termo, filtros])

  const headers: TableHeaders<Sacola>[] = [
    {
      label: "Id",
      wrapped: true,
      render: (item) => item.id,
    },
    {
      label: "Cliente",
      wrapped: true,
      render: (item) => item?.pessoa?.nome,
    },
    {
      label: "Valor Total",
      wrapped: false,
      render: (item) => formatNumber(item.valor_total ?? '0', 2),
    },
    {
      label: "Status",
      wrapped: false,
      render: (item) => <ShowArray arrayName="sacola_status" value={item.status * 1} />,
    },
    {
      label: "Data",
      wrapped: false,
      render: (item) => formatData(item?.created_at ?? ''),
    },
    {
      label: "Ações",
      wrapped: true,
      render: (item, index) => <>
        {<ButtonGroup>
          <ConfirmButton confirm={() => onDeleteItem(item.id as number)}><FiTrash /></ConfirmButton>
          <CamposPersonalizadosInfoButton camposPersonalizados={item?.camposPersonalizados ?? []} />
        </ButtonGroup>}
      </>,
      notClicable: true,
    },
  ];

  const onDeleteItem = (id: number) => {
    dispatch(entradaesaidaActions.deleteSacolaRequest(id));
  }

  const navigate = useNavigate();

  return (
    <Layout>
      <ListHeader label="Sacolas">
        <DefaultButton ml={4} to="/sacolas/novo"> Nova </DefaultButton>
      </ListHeader>



      <Loader isLoading={isLoading} />

      <ListInput label="Filtrar pelo cliente" onSubmit={setTermo}>
        <Formik initialValues={{
          status: filtros.status
        }}
          enableReinitialize={true}
          onSubmit={() => { }}
        >
          <Form>
            <InputSelectArray
              name="status"
              label=""
              arrayName="sacola_status"
              onChangeVal={(val) => val !== 'add_option' && setFiltros({ ...filtros, status: val })}
            />
          </Form>
        </Formik>
      </ListInput>

      {sacolas && <ResponsiveTable onClick={(item: any) => navigate(`/sacolas/${item.item.id}`)} headers={headers} data={sacolas.data} />}

      {sacolas && <Pagination info={sacolas} paginate={setPage} />}
    </Layout>
  )
}