import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { DefaultButton } from "../../sistema/components/DefaultButton"
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";
import { Formik, Form } from "formik";
import validateForm from "../../utils/validateForm";
import { useState, useEffect } from 'react'
import { Pessoa } from "../../cadastros/types/pessoa";
import { crmActions } from "../reducer";
import { useDispatch, useSelector } from "react-redux";
import { apiCall } from "../../utils/apiCall";
import { RootState } from "../../app/mainReducer";
import InputSelect from "../../sistema/components/InputSelect";
import InputField from "../../sistema/components/InputField";
import { CidadeBobjeto } from "../../cadastros/components/CidadeBobjeto";
import InputNumber from "../../sistema/components/InputNumber";
import { Filhos } from "../../sistema/components/Filhos";
import { retornaApenasNumero } from "../../utils/formatString";
import { novaPessoaContato } from "../../cadastros/data/pessoa_contato";
import { cadastroActions } from "../../cadastros/reducer";
import { mensagemErro, mensagemSucesso } from "../../utils/toasts";
import { formatError } from "../../utils/formatError";
import { ConfirmButton } from "../../sistema/components/ConfirmButton";
import { InputSelectArray } from "../../sistema/components/InputSelectArray";
import { CamposPersonalizadosForm } from "../../sistema/components/CamposPersonalizadosForm";

export const DadosPessoaButton = ({ pessoa_id, onDelete }: { pessoa_id: number | string, onDelete: () => void }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const [currentItem, setCurrentItem] = useState<Pessoa | null>(null);
  const [isLoadingLocal, setIsLoadingLocal] = useState<boolean>(false);
  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile)
  const sucessoCadastro = useSelector((state: RootState) => state.cadastro?.success)
  const onBuscaDados = async () => {
    try {
      dispatch(crmActions.isLoading(true))

      const { data } = await apiCall({
        url: `pessoas/${pessoa_id}?include=contatos`, method: 'GET'
      });

      setCurrentItem(data);

      dispatch(crmActions.isLoading(false))
    } catch (err: any) {
      dispatch(crmActions.isLoading(false))
      console.log(err);
    }
  }

  const onDeleteCliente = async () => {
    try {
      setIsLoadingLocal(true);
      await apiCall({
        url: `pessoas/${pessoa_id}`, method: 'DELETE'
      });
      setIsLoadingLocal(false);
      mensagemSucesso("Cliente apagado com sucesso!");
      onDelete()
      onClose();
    } catch (err: any) {
      mensagemErro(formatError(err))
      setIsLoadingLocal(false);
    }
  }

  const getLarguraPagina = (partes: number) => {
    if (isMobile) return '100%';

    return `${100 / partes}%`;
  }

  const getLarguraEspecifica = (largura: number) => {
    if (isMobile) return '100%';

    return `${largura}%`;
  }

  useEffect(() => {
    if (sucessoCadastro == 'Registro salvo com sucesso.') {
      onClose();
    }
  }, [sucessoCadastro, onClose])

  return (
    <>
      <DefaultButton onClick={() => { onOpen(); onBuscaDados() }} borderRadius={"2px"} m={"2px"} size={"sm"}>
        Dados cadastrais
      </DefaultButton>

      <Modal autoFocus={false} returnFocusOnClose={false} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"3xl"}>
        <ModalOverlay />
        <ModalContent top={-10}>
          <ModalHeader>
            <ListHeaderSecondary label={'Dados cadastrais'}>
              <DefaultButton onClick={onClose} ml={2}>Voltar</DefaultButton>
              <ConfirmButton colorSheme="red" isLoading={isLoadingLocal} size={"md"} confirm={onDeleteCliente} ml={2}>Apagar Cliente</ConfirmButton>
            </ListHeaderSecondary>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={2}>
            {currentItem && <Formik
              initialValues={currentItem}
              enableReinitialize
              onSubmit={(values, { setErrors }) => {
                const validation = validateForm({
                  nome: 'required',
                }, values)
                if (Object.keys(validation).length > 0) {
                  setErrors(validation)
                  return;
                }

                dispatch(cadastroActions.savePessoaRequest({
                  ...values,
                  changed: true,
                }));
              }}
            >
              {({ values }) => (
                <Form>
                  <Flex width="full" wrap={"wrap"}>
                    <InputField mask={(values.tipo_parceiro ?? '').trim() == 'Pessoa jurídica' ? '99.999.999/9999-99' : '999.999.999-99'} label="CNPJ/CPF" width={getLarguraPagina(3)} name="cpf_cnpj" />
                    <InputField label="Nome" width={getLarguraEspecifica(66)} name="nome" />
                    <InputField label="Endereço" name="endereco" width={getLarguraEspecifica(75)} />
                    <InputField label="Número" name="numero" width={getLarguraEspecifica(25)} />
                    <InputField mask="99.999-999" label="CEP" name="cep" width={getLarguraPagina(4)} />
                    <CidadeBobjeto width={getLarguraPagina(4)} />
                    <InputField label="Complemento" name="complemento" width={getLarguraPagina(4)} />
                    <InputSelect label="Status" name="status" width={getLarguraPagina(4)}>
                      <option value="Ativo">Ativo</option>
                      <option value="Inativo">Inativo</option>
                    </InputSelect>
                    <InputField label="E-mail" name="email" width={getLarguraPagina(4)} />
                    <InputField type="date" label="Data Integração" name="data_integracao" width={getLarguraPagina(4)} />
                    <InputNumber label="Limite de crédito" name="limite_credito" width={getLarguraPagina(4)} />
                    <InputSelectArray label="Empresa Origem" arrayName="empresas" name="array_empresas" width={getLarguraPagina(4)} />
                    <CamposPersonalizadosForm par="pessoa" idp={values?.id as number} />
                    <InputField label="Observações" name="observacoes" width={"100%"} textarea={true} rows={3} />

                    <Filhos
                      name="contatos"
                      tituloForm="Contatos"
                      editTituloForm="Contato"
                      novoItemObject={novaPessoaContato()}
                      validation={{ celular: 'required', tipo: 'required' }}
                      formatValuesBeforeSave={(values) => ({ ...values, celular: retornaApenasNumero(values.celular) })}
                      headers={[
                        {
                          label: "Tipo",
                          wrapped: false,
                          render: (item) => item.tipo,
                        },
                        {
                          label: "Nome",
                          wrapped: false,
                          render: (item) => item.nome,
                        },
                        {
                          label: "Celular",
                          wrapped: true,
                          render: (item) => item.celular,
                        },
                        {
                          label: "Observações",
                          wrapped: false,
                          render: (item) => item.observacoes,
                        }
                      ]}
                      form={(values) =>
                        <Flex width={"full"} wrap={"wrap"}>
                          <InputSelect width={getLarguraPagina(3)} name="tipo" label="Tipo de Contato">
                            <option value="Principal">Principal</option>
                            <option value="Comprador">Comprador</option>
                            <option value="Financeiro">Financeiro</option>
                            <option value="Vendedor">Vendedor</option>
                          </InputSelect>
                          <InputField name="nome" label="Nome da Pessoa" width={getLarguraPagina(3)} />
                          <InputField mask="(99)99999-9999" label="Celular" name="celular" width={getLarguraPagina(3)} />
                          <InputField textarea label="Observações" name="observacoes" rows={3} width={'100%'} />
                        </Flex>
                      }
                    />

                    <DefaultButton mt={1} type="submit" width={"full"}>
                      Salvar Alterações
                    </DefaultButton>
                  </Flex>

                </Form>)}

            </Formik>}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}