import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useIsAuth } from "../../hooks/useIsAuth"
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from 'react'
import Layout from "../../sistema/components/Layout";
import { ListHeader } from "../../sistema/components/ListHeader";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import Loader from "../../sistema/components/Loader";
import InputField from "../../sistema/components/InputField";
import { Form, Formik } from "formik";
import { Box, Button, ButtonGroup, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import validateForm from "../../utils/validateForm";
import { ButtonHistorico } from "../../sistema/components/ButtonHistorico";
import InputNumber from "../../sistema/components/InputNumber";
import { InputSelectArray } from "../../sistema/components/InputSelectArray";
import { CamposPersonalizadosForm } from "../../sistema/components/CamposPersonalizadosForm";
import { financeiroActions } from "../reducer";
import { novoFinanceiroParcela } from "../data/financeiro_parcela";
import { PessoaBobjeto } from "../../cadastros/components/PessoaBobjeto";
import { mensagemErro } from "../../utils/toasts";
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";
import { FiDollarSign, FiPlus, FiTrash } from "react-icons/fi";
import ResponsiveTable, { TableHeaders } from "../../sistema/components/ResponsiveTable";
import InputCheck from "../../sistema/components/InputCheck";
import { Financeiro } from "../types/financeiro";
import { FinanceiroParcela } from "../types/financeiro_parcela";
import { addMonths, isBefore, parse } from "date-fns";
import _ from "lodash";
import { formatData, formatDataObject } from "../../utils/data";
import { formatValor } from "../../utils/formatValor";
import { FinanceiroParcelaQuitacao } from "../types/financeiro_quitacao";
import { DeleteButton } from "../../sistema/components/DeleteButton";
import { apiCall } from "../../utils/apiCall";
import { formatError } from "../../utils/formatError";

export const FinanceiroEdit = () => {
  useIsAuth();
  const navigate = useNavigate();

  // tipo = pagar, receber
  const { id, tipo } = useParams();

  const [tipoFinanceiro, setTipoFinanceiro] = useState("A Pagar");

  useEffect(() => {
    if (tipo == 'receber') {
      setTipoFinanceiro('A Receber')
      return;
    }
    setTipoFinanceiro('A Pagar')

    if (tipo != 'receber' && tipo != 'pagar') {
      mensagemErro("Não autorizado.");
      navigate(-1);

    }
  }, [tipo, setTipoFinanceiro])

  const financeiro = useSelector((state: RootState) => state.financeiro.financeiro || null)
  const isLoading = useSelector((state: RootState) => !!state.financeiro.isLoading)
  const isMobile = useSelector((state: RootState) => !!state.sistema.isMobile)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!id) return;

    dispatch(financeiroActions.financeiroRequest({ id, tipo: tipo as any }))
  }, [dispatch, id, tipo])

  const w1_3 = isMobile ? '100%' : '33%';

  const DeleteParcelaButton = ({ confirmou }: { confirmou: () => void; }) => {
    const [abriu, setAbriu] = useState(false);

    if (abriu) {
      return (
        <ButtonGroup bgColor={"yellow.50"} p={1} borderRadius={"10px"}>
          <Button onClick={() => setAbriu(false)} size={"sm"} colorScheme="blackAlpha">
            Cancelar
          </Button>
          <Button onClick={() => { setAbriu(false); confirmou(); }} size={"sm"} colorScheme="red">
            Confirmar
          </Button>
        </ButtonGroup>
      )
    }

    return (
      <Button onClick={() => setAbriu(true)} title="Apagar parcela" size={"sm"} colorScheme="red" p={2}>
        <FiTrash />
      </Button>
    )
  }

  const buscaNovaParcela = (values: Financeiro) => {
    const parcelas = values?.parcelas ?? [];
    const novaParcela = novoFinanceiroParcela();

    return [
      ...parcelas,
      {
        ...novaParcela,
        codigo: String(parcelas.length + 1).padStart(3, '0'),
        changed: true,
      }
    ]
  }

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenQuitacoes, onOpen: onOpenQuitacoes, onClose: onCloseQuitacoes } = useDisclosure();

  const [quitacoesModel, setQuitacoesModel] = useState<FinanceiroParcela | null>(null);
  const [isLoadingApagaQuitacao, setIsLoadingApagaQuitacao] = useState(false);

  const onDeleteQuitacao = async (item: FinanceiroParcelaQuitacao) => {
    try {
      setIsLoadingApagaQuitacao(true);
      const { data } = await apiCall({ url: `apaga-quitacao/${item.id}`, method: 'POST' });
      setQuitacoesModel(data);
      setIsLoadingApagaQuitacao(false);
    } catch (err) {
      setIsLoadingApagaQuitacao(false);
      mensagemErro(formatError(err));
    }
  }

  return (
    <Layout>
      <ListHeader label={`Contas ${tipoFinanceiro} ${financeiro?.codigo ? ` - ${financeiro.codigo}` : ''}`}>
        <DefaultButton ml={4} to={-1}> Voltar </DefaultButton>
      </ListHeader>

      <Loader isLoading={isLoading} />

      {financeiro && <Formik
        enableReinitialize
        initialValues={financeiro}
        onSubmit={(val, { setErrors }) => {
          const validation = validateForm({
            data_emissao: 'required',
            tipo_pagamento: 'required',
            pessoa_id: 'required',
          }, val)
          if (Object.keys(validation).length > 0) {
            setErrors(validation)
            return;
          }

          if (val.parcelas?.length === 0) {
            mensagemErro("Adicione ao menos 1 parcela.");
            return;
          }

          for (const parcela of (val.parcelas ?? [])) {
            if (!parcela.data_vencimento || parseFloat(parcela?.valor ?? '0') <= 0) {
              mensagemErro("Todas as parcelas devem ter a data de vencimento e o valor.")
              return;
            }
          }

          dispatch(financeiroActions.saveFinanceiroRequest({
            ...val,
            tipo: tipo as any,
            changed: true,
            parcelas: (val.parcelas ?? []).map((i) => ({ ...i, _valor_baixa: (val as any)._salva_e_quita == 1 ? i.valor as any : 0 }))
          }));
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Flex width="full" wrap="wrap">
              <InputField width={w1_3} type="date" label="Emissão" name="data_emissao" />
              <InputSelectArray width={w1_3} label="Tipo de Pagamento" arrayName="financeiro_tipo_pagamento" name="tipo_pagamento" />
              <PessoaBobjeto label="Pessoa" width={w1_3} name="pessoa_id" />
              <InputField textarea rows={3} label="Descrição" name="descricao" />
              {!values.id && <InputCheck name="_salva_e_quita" label="Salva e já quita o financeiro" />}

              <CamposPersonalizadosForm par="financeiro" idp={financeiro?.id as number} />

              <Modal closeOnOverlayClick={false} isOpen={isOpenQuitacoes} onClose={onCloseQuitacoes} size={"2xl"}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>
                    <ListHeaderSecondary label={`Quitações da parcela ${quitacoesModel?.codigo}`} />
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    {(quitacoesModel?.quitacoes ?? []).length > 0 && <ResponsiveTable isSmall headers={[
                      {
                        label: "Valor",
                        wrapped: false,
                        render: (item) => `R$ ${formatValor(item.valor, 2)}`,
                      },
                      {
                        label: "Data Baixa",
                        wrapped: false,
                        render: (item) => formatData(item.data_hora, 'dd/MM/yyy')
                      },
                      {
                        label: "Quando foi feito",
                        wrapped: true,
                        render: (item) => formatData(item?.created_at ?? '', 'dd/MM/yyy HH:mm')
                      },
                      {
                        label: "Ações",
                        wrapped: false,
                        render: (item) => <DeleteButton isLoading={isLoadingApagaQuitacao} confirmou={() => onDeleteQuitacao(item)} />
                      },
                    ] as TableHeaders<FinanceiroParcelaQuitacao>[]
                    } data={quitacoesModel?.quitacoes ?? []} />}
                  </ModalBody>
                </ModalContent>
              </Modal>

              <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"md"}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>
                    <ListHeaderSecondary label={'Incluir parcelas em massa'} />
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <Formik
                      enableReinitialize
                      initialValues={{
                        numero_parcelas: 1,
                        dia_vencimento: 0,
                        valor_fixo: 0,
                      }}
                      onSubmit={(val, { setErrors }) => {
                        console.log(val);

                        const validation = validateForm({
                          numero_parcelas: 'min_number|0',
                          dia_vencimento: 'min_number|0',
                          valor_fixo: 'min_number|0',
                        }, val)
                        if (Object.keys(validation).length > 0) {
                          setErrors(validation)
                          return;
                        }

                        if (val.dia_vencimento > 31 || val.dia_vencimento <= 0) {
                          setErrors({
                            dia_vencimento: "Deve ser informado um dia válido!",
                          })
                          return;
                        }

                        let dataInicial = parse(
                          `${val.dia_vencimento}`.padStart(2, "0"),
                          "dd",
                          new Date()
                        );
                        if (isBefore(dataInicial, new Date())) {
                          dataInicial = addMonths(dataInicial, 1);
                        }
                        const parcelasNovas = [...(values?.parcelas ?? [])];
                        const maxNum = parseInt(
                          _.maxBy(parcelasNovas, (p) => p.codigo)?.codigo as string
                        );
                        for (let i = 0; i < val.numero_parcelas * 1; i++) {
                          parcelasNovas.push({
                            ...novoFinanceiroParcela(),
                            codigo: `${(maxNum || 0) + 1 + i}`.padStart(3, "0"),
                            data_vencimento: formatDataObject(addMonths(dataInicial, i), "yyyy-MM-dd"),
                            valor: val.valor_fixo * 1 as any,
                            changed: true,
                          }
                          );
                        }

                        setFieldValue('parcelas', parcelasNovas);

                        onClose();
                      }}
                    >
                      <Form>
                        <Flex width={"full"} wrap={"wrap"}>
                          <InputNumber name="numero_parcelas" decimalPlaces={0} label="Número de parcelas" width={"full"} />
                          <InputNumber name="dia_vencimento" decimalPlaces={0} label="Dia do vencimento" width={"full"} />
                          <InputNumber name="valor_fixo" decimalPlaces={2} label="Valor fixo das parcelas" width={"full"} />

                          <DefaultButton type="submit" width={"full"} mt={2}>
                            Gerar Parcelas
                          </DefaultButton>
                        </Flex>
                      </Form>
                    </Formik>
                  </ModalBody>
                </ModalContent>
              </Modal>
              <ListHeaderSecondary label={'Parcelas'}>
                <DefaultButton size="sm" ml={4} onClick={() => setFieldValue('parcelas', buscaNovaParcela(values))}>Nova parcela</DefaultButton>
                <DefaultButton size="sm" ml={4} onClick={onOpen}>Incluir parcelas em massa</DefaultButton>
              </ListHeaderSecondary>

              {<ResponsiveTable headers={[
                {
                  label: "#",
                  widthDesktop: "15%",
                  render: (item, index) => item.codigo,
                },
                {
                  label: "Vencimento",
                  widthDesktop: "30%",
                  render: (item, index) => <InputField disabled={item.status == 2} type="date" label="" name={`parcelas[${index}].data_vencimento`} />,
                },
                {
                  label: "Valor (R$)",
                  widthDesktop: "30%",
                  render: (item, index) => <InputNumber disabled={item.status == 2} name={`parcelas[${index}].valor`} label="" width={"full"} />
                },
                {
                  label: "Ações",
                  widthDesktop: "25%",
                  render: (item, index) => <ButtonGroup>
                    <DeleteParcelaButton confirmou={() => {
                      if (item.id) {
                        const indexItem = (values?.parcelas ?? []).findIndex((j) => j.id == item.id);
                        const parcelas: FinanceiroParcela[] = JSON.parse(JSON.stringify((values?.parcelas ?? [])));
                        parcelas[indexItem] = { ...item, deleted: true, changed: true, };

                        const parcelasSemDeleted = parcelas.filter((i) => !i.deleted);
                        const parcelasComDeleted = parcelas.filter((i) => i.deleted);
                        setFieldValue(`parcelas`, [
                          ...parcelasSemDeleted.map((p, index) => ({ ...p, codigo: String(index + 1).padStart(3, '0'), changed: true, })),
                          ...parcelasComDeleted,
                        ])
                        return;
                      }

                      const parcelas: FinanceiroParcela[] = JSON.parse(JSON.stringify((values?.parcelas ?? [])));
                      parcelas.splice(index, 1);
                      setFieldValue(`parcelas`, parcelas.map((p, index) => ({ ...p, codigo: String(index + 1).padStart(3, '0'), changed: true, })))
                    }} />
                    {index === (values.parcelas?.length ?? 0) - 1 && <Button onClick={() => setFieldValue('parcelas', buscaNovaParcela(values))} title="Adicionar parcela" size={"sm"} colorScheme="blue" p={2}>
                      <FiPlus />
                    </Button>}
                    {(item?.quitacoes ?? []).length > 0 && <Button size={"sm"} title="Quitações" colorScheme="blackAlpha" p={2} onClick={() => { setQuitacoesModel(item); onOpenQuitacoes() }}><FiDollarSign /></Button>}
                  </ButtonGroup>
                },
              ]} data={(values.parcelas ?? []).filter((u) => !u.deleted)} />}

              <Flex width={"full"} />
              <Box
                bg={"white"}
                mt={2}
                width={"full"}
                position="sticky"
                bottom="10px"
                zIndex="999"
                p={2}
              >
                <DefaultButton type="submit">Salvar cadastro</DefaultButton>
                {values.id && <ButtonHistorico par="pessoa" idpar={values.id} ml={2} />}
              </Box>
            </Flex>
          </Form>
        )}
      </Formik>}
    </Layout>
  )
}