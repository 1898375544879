import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { mensagemErro, mensagemSucesso } from "../utils/toasts";
import { Pessoa } from "./types/pessoa";
import { ApiPaginationRes } from "../sistema/types/apiPaginationRes";
import { ListPayload } from "../sistema/types/listPayload";
import { TabelaPreco } from "./types/tabela_preco";
import { Cidade } from "./types/cidade";
import { Rota } from "./types/rota";
import { Lista } from "./types/lista";

type TInitialState = {
  error?: string;
  success?: string;
  isLoading?: boolean;
  pessoas?: ApiPaginationRes<Pessoa[]>;
  pessoa?: Pessoa | null;
  cidades?: ApiPaginationRes<Cidade[]>;
  cidade?: Cidade | null;
  rotas?: ApiPaginationRes<Rota[]>;
  rota?: Rota | null;
  tabelas?: ApiPaginationRes<TabelaPreco[]>;
  tabela?: TabelaPreco | null;
  listas?: ApiPaginationRes<Lista[]>;
  lista?: Lista | null;
};

const initialState: TInitialState = {};

const reducer = createSlice({
  name: "cadastro",
  initialState,
  reducers: {
    error(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = payload;
      state.success = "";
      mensagemErro(payload);
    },
    success(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = "";
      state.success = payload;
      mensagemSucesso(payload);
    },
    pessoasRequest(state, _: PayloadAction<ListPayload>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    pessoasRequestSuccess(
      state,
      { payload }: PayloadAction<ApiPaginationRes<Pessoa[]>>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.pessoas = payload;
    },
    pessoaRequest(state, _: PayloadAction<string>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
      state.pessoa = null;
    },
    pessoaRequestSuccess(state, { payload }: PayloadAction<Pessoa>) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.pessoa = payload;
    },
    savePessoaRequest(state, _: PayloadAction<Pessoa>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    savePessoaRequestSuccess(state, { payload }: PayloadAction<Pessoa>) {
      state.isLoading = false;
      state.error = "";
      state.success = "Registro salvo com sucesso.";
      state.pessoa = payload;
      mensagemSucesso("Registro salvo com sucesso.");
    },
    deletePessoaRequest(state, _: PayloadAction<string | number>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    deletePessoaRequestSuccess(state) {
      state.isLoading = false;
      state.error = "";
      state.success = "Registro apagado com sucesso.";
      mensagemSucesso("Registro apagado com sucesso.");
    },

    cidadesRequest(state, _: PayloadAction<ListPayload>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    cidadesRequestSuccess(
      state,
      { payload }: PayloadAction<ApiPaginationRes<Cidade[]>>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.cidades = payload;
    },
    cidadeRequest(state, _: PayloadAction<string>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
      state.cidade = null;
    },
    cidadeRequestSuccess(state, { payload }: PayloadAction<Cidade>) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.cidade = payload;
    },
    saveCidadeRequest(state, _: PayloadAction<Cidade>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    saveCidadeRequestSuccess(state, { payload }: PayloadAction<Cidade>) {
      state.isLoading = false;
      state.error = "";
      state.success = "Registro salvo com sucesso.";
      state.cidade = payload;
      mensagemSucesso("Registro salvo com sucesso.");
    },
    deleteCidadeRequest(state, _: PayloadAction<string | number>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    deleteCidadeRequestSuccess(state) {
      state.isLoading = false;
      state.error = "";
      state.success = "Registro apagado com sucesso.";
      mensagemSucesso("Registro apagado com sucesso.");
    },

    listasRequest(state, _: PayloadAction<ListPayload>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    listasRequestSuccess(
      state,
      { payload }: PayloadAction<ApiPaginationRes<Lista[]>>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.listas = payload;
    },
    listaRequest(state, _: PayloadAction<string>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
      state.lista = null;
    },
    listaRequestSuccess(state, { payload }: PayloadAction<Lista>) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.lista = payload;
    },
    saveListaRequest(state, _: PayloadAction<Lista>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    saveListaRequestSuccess(state, { payload }: PayloadAction<Lista>) {
      state.isLoading = false;
      state.error = "";
      state.success = "Registro salvo com sucesso.";
      state.lista = payload;
      mensagemSucesso("Registro salvo com sucesso.");
    },
    deleteListaRequest(state, _: PayloadAction<string | number>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    deleteListaRequestSuccess(state) {
      state.isLoading = false;
      state.error = "";
      state.success = "Registro apagado com sucesso.";
      mensagemSucesso("Registro apagado com sucesso.");
    },

    rotasRequest(state, _: PayloadAction<ListPayload>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    rotasRequestSuccess(
      state,
      { payload }: PayloadAction<ApiPaginationRes<Rota[]>>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.rotas = payload;
    },
    rotaRequest(state, _: PayloadAction<string>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
      state.rota = null;
    },
    rotaRequestSuccess(state, { payload }: PayloadAction<Rota>) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.rota = payload;
    },
    saveRotaRequest(state, _: PayloadAction<Rota>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    saveRotaRequestSuccess(state, { payload }: PayloadAction<Rota>) {
      state.isLoading = false;
      state.error = "";
      state.success = "Registro salvo com sucesso.";
      state.rota = payload;
      mensagemSucesso("Registro salvo com sucesso.");
    },
    deleteRotaRequest(state, _: PayloadAction<string | number>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    deleteRotaRequestSuccess(state) {
      state.isLoading = false;
      state.error = "";
      state.success = "Registro apagado com sucesso.";
      mensagemSucesso("Registro apagado com sucesso.");
    },

    tabelasRequest(state, _: PayloadAction<ListPayload>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    tabelasRequestSuccess(
      state,
      { payload }: PayloadAction<ApiPaginationRes<TabelaPreco[]>>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.tabelas = payload;
    },
    tabelaRequest(state, _: PayloadAction<string>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
      state.tabela = null;
    },
    tabelaRequestSuccess(state, { payload }: PayloadAction<TabelaPreco>) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.tabela = payload;
    },
    saveTabelaRequest(state, _: PayloadAction<TabelaPreco>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    saveTabelaRequestSuccess(state, { payload }: PayloadAction<TabelaPreco>) {
      state.isLoading = false;
      state.error = "";
      state.success = "Registro salvo com sucesso.";
      state.tabela = payload;
      mensagemSucesso("Registro salvo com sucesso.");
    },
    deleteTabelaRequest(state, _: PayloadAction<string | number>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    deleteTabelaRequestSuccess(state) {
      state.isLoading = false;
      state.error = "";
      state.success = "Registro apagado com sucesso.";
      mensagemSucesso("Registro apagado com sucesso.");
    },
  },
});

export const cadastroActions = reducer.actions;

export default reducer;
