import { useIsAuth } from "../../hooks/useIsAuth"
import Layout from "../components/Layout";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useState, useEffect } from 'react';
import Loader from "../components/Loader";
import { Box, Flex, Heading, Spinner, Stack, StackDivider, Text, VStack } from "@chakra-ui/react";
import { apiCall } from "../../utils/apiCall";
import { SistemaAssinatura } from "../types/sistema_assinatura";
import { formatData } from "../../utils/data";
import { formatValor } from "../../utils/formatValor";
import { DefaultButton } from "../components/DefaultButton";
import { ListHeaderSecondary } from "../components/ListHeaderSecondary";

export const Mensalidade = () => {
  useIsAuth();

  const isLoading = useSelector((state: RootState) => !!state.sistema.isLoading);
  const isMobile = useSelector((state: RootState) => !!state.sistema.isMobile);
  const dispatch = useDispatch()
  const [historicos, setHistoricos] = useState<SistemaAssinatura[]>([])
  const [aPagars, setAPagars] = useState<SistemaAssinatura[]>([])
  const [isLoadingHistorico, setIsLoadingHistorico] = useState<boolean>(true)
  const [isLoadingAPagars, setIsLoadingAPagars] = useState<boolean>(true)

  useEffect(() => {
    const getHistorico = async () => {
      try {
        setIsLoadingHistorico(true)
        const { data } = await apiCall({
          url: `mensalidade-historico`, method: 'GET'
        });
        setHistoricos(data);
        setIsLoadingHistorico(false)
      } catch (err: any) {
        setIsLoadingHistorico(false)
      }
    }
    const getAPagar = async () => {
      try {
        setIsLoadingAPagars(true)
        const { data } = await apiCall({
          url: `mensalidade-a-pagar`, method: 'GET'
        });
        setAPagars(data);
        setIsLoadingAPagars(false)
      } catch (err: any) {
        setIsLoadingAPagars(false)
      }
    }
    getHistorico()
    getAPagar();
  }, [dispatch])

  const TimelineItem = ({ historico }: { historico: SistemaAssinatura }) => (
    <Flex direction="column" alignItems="center" mb={8} width={"full"} pr={2}>
      <Box
        w="10px"
        h="10px"
        borderRadius="50%"
        bg="teal.500"
        mb={2}
      />
      <Box
        w="2px"
        h="50px"
        bg="teal.500"
        mb={2}
      />
      <Box p={4} borderWidth="1px" borderRadius="md" boxShadow="md" maxW="sm" width={"full"} bg={"white"}>
        <Stack divider={<StackDivider />} spacing='4'>
          <Box>
            <Heading size='xs' textTransform='uppercase' color="gray.500" >
              <b> {historico.competencia_mes} de {historico.competencia_ano} </b>
            </Heading>
          </Box>
          <Box>
            <Text pt='2' fontSize='sm' justifyContent={"space-between"}>
              <Flex wrap={"wrap"} width={"full"}>
                <Flex width={"100%"} direction={"column"}>
                  <span> Vencimento: <b> {formatData(historico.data_vencimento, "dd/MM/yyyy")} </b> </span>
                  <span> Valor: <b> R$ {formatValor(historico.valor)} </b> </span>
                  <span> Status: <b> PAGO </b> </span>
                </Flex>
              </Flex>
            </Text>
          </Box>
        </Stack>
      </Box>
    </Flex>
  );


  const VerticalTimeline = () => (
    <VStack align="stretch" spacing={0} width={"full"}>
      {historicos.length == 0 && !isLoadingHistorico && <Heading size={"md"}>
        Nenhum pagamento encontrado
      </Heading>}

      {historicos.map((i, index) => (
        <TimelineItem key={`${index}`} historico={i} />
      ))}
    </VStack>
  );

  const APagar = () => {
    return (
      <Flex width={"full"} direction={"column"} wrap={"wrap"}>
        {aPagars.length == 0 && !isLoadingAPagars && <Heading size={"md"}>
          Nenhuma conta encontrada
        </Heading>}
        {aPagars.map((i, index) => (
          <Flex my={2} key={index} width={"full"} wrap={"wrap"} bg={"white"} p={4} borderRadius="md" boxShadow="md">
            <Stack divider={<StackDivider />} spacing='4' width={"full"}>
              <Box width={"full"}>
                <Heading size='xs' textTransform='uppercase' color="gray.500" >
                  <b> {i.competencia_mes}</b> de <b> {i.competencia_ano}</b>
                </Heading>
              </Box>
              <Box width={"full"}>
                Vencimento: <b> {formatData(i.data_vencimento, "dd/MM/yyyy")} </b> <br />
                Valor: <b> R$ {formatValor(i.valor)} </b> <br />
                Status: <b>EM ABERTO</b> <br />
                <small>* O boleto pode levar até 2 dias úteis a ser compensado.</small>

                <DefaultButton mt={4} width={"full"} borderRadius={"5px"} onClick={() => window.open(i.boleto, '_blank')}>
                  Pagar com Boleto
                </DefaultButton>
              </Box>
            </Stack>
          </Flex>
        ))}
      </Flex>
    )
  }

  return (
    <Layout>
      <Loader isLoading={isLoading} />

      <Flex width={"full"} wrap={"wrap-reverse"}>
        <Flex width={isMobile ? '100%' : '30%'} wrap={"wrap"} direction={"column"}>
          <Heading my={3} size={"md"} width={"full"}>
            <ListHeaderSecondary label="Histórico" />
          </Heading>

          <Flex wrap={"wrap"} w={"full"} alignItems={"start"}>
            {isLoadingHistorico && <Spinner />}
            <VerticalTimeline />
          </Flex>
        </Flex>
        <Flex width={isMobile ? '100%' : '70%'} direction={"column"}>
          <Heading my={3} size={"md"} width={"full"}>
            <ListHeaderSecondary label="A Pagar" />
          </Heading>
          <Flex wrap={"wrap"} w={"full"}>
            {isLoadingAPagars && <Spinner />}
            <APagar />
          </Flex>
        </Flex>
      </Flex>
    </Layout>
  )
}