import { Flex, Text } from "@chakra-ui/react"

interface IProps {
  label?: string;
  children?: any;
}

export const ListHeaderSecondary = ({ label, children }: IProps) => {
  return (
    <Flex wrap="wrap" width="full" borderBottom="1px solid #a0a0a0" pb={2}>
      <Text fontSize="1.7rem" color="#404244" fontWeight="700">
        {label}

        {children}
      </Text>
    </Flex>
  )
}